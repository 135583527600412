import { Article } from './styled';
import ReactLoading from 'react-loading';

const Loading = () => {
  return (
    <Article>
      <ReactLoading type={'bars'} color="#3f83f1" />
    </Article>
  );
};

export default Loading;
