import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { AutoComplete } from 'src/Pages/Shipments/styled';
import {
  CreateCountryAssociation,
  DeleteCountryAssociation,
  UpdateCountryAssociation,
} from 'src/Reducers/mutations';
import {
  GetCountriesAssociations,
  GetCountriesList,
  GetCountryCodes,
} from 'src/Reducers/queries';
import Grid from '@mui/material/Unstable_Grid2';
import styled from 'styled-components';
import CloseIcon from 'src/Assets/Images/CloseIcon';
import { useCountryAssoc } from 'src/hooks/use-country-assoc';
import { onError } from '@apollo/client/link/error';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type SelectProps = {
  searchText: string;
  setSearchText(value: string): void;
};

type CustomButtonProps = {
  handler(): void;
  isLoading: boolean;
  title: 'Delete' | 'Save' | 'Update';
};

type SelectRowProps = {
  forvarderHomeCountry: string;
  groupCountriesFirst: string;
  groupCountriesSecond: string;
  groupCountriesThird: string;
  refetch(): void;
  onError(): void;
};

const ModalAnchor = styled(Box)`
  width: 800px;
  height: 450px;
  padding: 50px 15px;
  border: none;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  inset: 0;
  margin: auto;
`;

const ScrollGrid = styled(Grid)`
  overflow-y: scroll !important;
  max-height: 200px !important;
  margin-bottom: 20px !important;
`;

const Converter = () => {
  const [searchText, setSearchText] = useState('');
  const [, setOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [code, setCode] = useState('');

  const countriesList = useQuery(GetCountryCodes, {
    variables: {
      country: searchText,
    },
  });
  useEffect(() => {
    if (countriesList.data) {
      setCountryList(
        countriesList.data.getCountryCodes.map((item: any) => item.countryname)
      );
      if (countriesList.data.getCountryCodes.length >= 1) {
        setCode(countriesList.data.getCountryCodes[0].code);
      }
    }
  }, [countriesList.data]);
  return (
    <>
      <Grid xs={12}>
        <Typography>Country code</Typography>
      </Grid>
      <Grid xs={10}>
        <AutoComplete
          sx={{ width: '100%' }}
          id="size-small-standard"
          autoComplete
          options={countryList || []}
          filterSelectedOptions
          autoSelect
          autoHighlight
          loading={countriesList.loading}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInputChange={(event: React.SyntheticEvent, value: string) => {
            setSearchText(value ? value : '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              className="textFields"
              placeholder="All"
            />
          )}
          inputValue={searchText}
          value={searchText}
        />
      </Grid>
      <Grid
        xs={2}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography>{code ? code : 'code'}</Typography>
      </Grid>
    </>
  );
};

const Select = ({ searchText, setSearchText }: SelectProps) => {
  const [, setOpen] = useState(false);

  const countriesList = useQuery(GetCountriesList, {
    variables: {
      searchText: searchText,
    },
  });

  return (
    <AutoComplete
      sx={{ width: '100%' }}
      id="size-small-standard"
      autoComplete
      filterOptions={(x) => countriesList?.data?.getCountriesList || []}
      options={countriesList?.data?.getCountriesList || []}
      filterSelectedOptions
      autoSelect
      autoHighlight
      loading={countriesList.loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event: React.SyntheticEvent, value: string) => {
        setSearchText(value ? value.toUpperCase() : '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          className="textFields"
          placeholder="All"
        />
      )}
      inputValue={searchText}
      value={searchText}
    />
  );
};

const CustomButton = ({ handler, isLoading, title }: CustomButtonProps) => (
  <Button
    onClick={handler}
    sx={{
      backgroundColor: 'rgba(5,21,81,0.9)',
      color: 'white',
      width: '100%',
      ':hover': { backgroundColor: 'rgba(44, 74, 184, 0.9)' },
    }}
  >
    {isLoading ? 'Loading...' : title}
  </Button>
);

const SelectRow = ({
  forvarderHomeCountry,
  groupCountriesFirst,
  groupCountriesSecond,
  groupCountriesThird,
  refetch,
  onError,
}: SelectRowProps) => {
  const [setCountryAssoc] = useMutation(CreateCountryAssociation);
  const [deleteCountryAssoc] = useMutation(DeleteCountryAssociation);
  const [updateCountryAssoc] = useMutation(UpdateCountryAssociation);
  const [forvarderHomeCountryCode, setForvarderHomeCountryCode] =
    useState<string>(forvarderHomeCountry);
  const [groupCountriesCodeFirst, setGroupCountriesCodeFirst] =
    useState<string>(groupCountriesFirst);
  const [groupCountriesCodeSecond, setGroupCountriesCodeSecond] =
    useState<string>(groupCountriesSecond);
  const [groupCountriesCodeThird, setGroupCountriesCodeThird] =
    useState<string>(groupCountriesThird);
  const [isLoading, setIsLoading] = useState(false);

  const saveCountriesList = async () => {
    setIsLoading(true);
    const payload = {
      userId: 806,
      homeCountry: forvarderHomeCountryCode,
      countriesAssociated: [
        groupCountriesCodeFirst,
        groupCountriesCodeSecond,
        groupCountriesCodeThird,
      ],
    };
    try {
      if (!forvarderHomeCountryCode) throw new Error('error');
      await setCountryAssoc({
        variables: payload,
      });
    } catch (error) {
      onError();
    } finally {
      refetch();
      setIsLoading(false);
    }
  };

  const deleteCountriesList = async () => {
    setIsLoading(true);
    try {
      if (!forvarderHomeCountryCode) throw new Error('error');
      await deleteCountryAssoc({
        variables: {
          homeCountry: forvarderHomeCountryCode,
        },
      });
    } catch (error) {
      onError();
    } finally {
      refetch();
      setIsLoading(false);
    }
  };

  const updateCountriesList = async () => {
    setIsLoading(true);
    const payload = {
      userId: 806,
      homeCountry: forvarderHomeCountryCode,
      countriesAssociated: [
        groupCountriesCodeFirst,
        groupCountriesCodeSecond,
        groupCountriesCodeThird,
      ],
      previousHomeCountry: forvarderHomeCountry,
    };
    try {
      if (!forvarderHomeCountryCode) throw new Error('error');
      await updateCountryAssoc({
        variables: payload,
      });
    } catch (error) {
      onError();
    } finally {
      refetch();
      setIsLoading(false);
    }
  };

  const getButtonProps = () => {
    if (
      !forvarderHomeCountry &&
      !groupCountriesFirst &&
      !groupCountriesSecond &&
      !groupCountriesThird
    ) {
      return {
        title: 'Save',
        handler: saveCountriesList,
        isLoading,
      } as CustomButtonProps;
    } else if (
      forvarderHomeCountry === forvarderHomeCountryCode &&
      groupCountriesFirst === groupCountriesCodeFirst &&
      groupCountriesSecond === groupCountriesCodeSecond &&
      groupCountriesThird === groupCountriesCodeThird
    ) {
      return {
        title: 'Delete',
        handler: deleteCountriesList,
        isLoading,
      } as CustomButtonProps;
    } else {
      return {
        title: 'Update',
        handler: updateCountriesList,
        isLoading,
      } as CustomButtonProps;
    }
  };

  return (
    <>
      <Grid xs={3}>
        <Select
          searchText={forvarderHomeCountryCode}
          setSearchText={setForvarderHomeCountryCode}
        />
      </Grid>
      <Grid xsOffset={2} xs={2}>
        <Select
          searchText={groupCountriesCodeFirst}
          setSearchText={setGroupCountriesCodeFirst}
        />
      </Grid>
      <Grid xs={2}>
        <Select
          searchText={groupCountriesCodeSecond}
          setSearchText={setGroupCountriesCodeSecond}
        />
      </Grid>
      <Grid xs={2}>
        <Select
          searchText={groupCountriesCodeThird}
          setSearchText={setGroupCountriesCodeThird}
        />
      </Grid>
      <Grid
        xs={1}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CustomButton {...getButtonProps()} />
      </Grid>
    </>
  );
};

export const GroupCountriesModal = ({ isOpen, setIsOpen }: ModalProps) => {
  const [state, setState] = useState<number>(0);
  const [
    countryAssoc,
    refetchCountryAssoc,
    isLoading,
    addCountries,
    removeEmpty,
  ] = useCountryAssoc();
  useEffect(() => {
    refetchCountryAssoc();
  }, []);

  const forceUpdate = () => {
    setState((prev) => prev + 1);
  };

  useEffect(() => {
    forceUpdate();
  }, [countryAssoc]);

  return (
    <Modal open={isOpen}>
      <ModalAnchor key={state}>
        <Button
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={() => {
            removeEmpty();
            setIsOpen(false);
          }}
        >
          <CloseIcon />
        </Button>
        <Grid container spacing={1}>
          <Converter />
          <Grid xs={3}>
            <Typography sx={{ textAlign: 'center' }}>
              Forwarder home country
            </Typography>
          </Grid>
          <Grid xsOffset={2} xs={7}>
            <Typography sx={{ textAlign: 'center' }}>
              Group countries
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <ScrollGrid container spacing={1} xs={12}>
            {!isLoading ? (
              countryAssoc.length > 0 ? (
                countryAssoc.map((country, index) => (
                  <SelectRow
                    refetch={refetchCountryAssoc}
                    forvarderHomeCountry={country.forvarderHomeCountry}
                    groupCountriesFirst={country.groupCountriesFirst}
                    groupCountriesSecond={country.groupCountriesSecond}
                    groupCountriesThird={country.groupCountriesThird}
                    key={index}
                    onError={removeEmpty}
                  />
                ))
              ) : (
                <Typography sx={{ textAlign: 'center', width: '100%' }}>
                  No data
                </Typography>
              )
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  inset: 0,
                  margin: 'auto',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </ScrollGrid>
        </Grid>
        <Button onClick={addCountries}>+ Add</Button>
      </ModalAnchor>
    </Modal>
  );
};
