import { useState } from 'react';
import { useContext } from 'react';
import { errorHandlerContext } from 'src/contexts';

export function useProvideErrorHandler() {
  const defaultMsgConfig = {
    alertOpen: false,
    handleClose: Function,
    message: 'Loading...',
    severity: 'info',
  };

  const handleClose = () => {
    setMsgConfig({
      ...msgConfig,
      alertOpen: false,
    });
  };

  const [msgConfig, setMsgConfig] = useState<any>(defaultMsgConfig);
  return {
    msgConfig,
    setMsgConfig,
    handleClose,
  };
}

export function useErrorHandler() {
  return useContext(errorHandlerContext);
}
