import { Stack, Typography, Snackbar } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { BorderRadiusButton } from './styled';
import { useMutation } from '@apollo/client';
import { DoLogin } from 'src/Reducers/mutations';
import { CompanyTypes } from 'src/Utils/UserPermission';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import InfoModal from '../../InfoModal';
import { AuthBaseModal } from '../AuthBaseModal';
import {
  TextInput,
  PasswordInput,
  SubmitButton,
  CheckBoxWithLabel,
} from '../components/auth';
import { ForgetPassword } from '../ForgetPassword';
import { IntroOverlay } from 'src/Components/IntroOverlay';

export type SignInModalProps = {
  isOpen: boolean;
  onClose(): void;
  openSignUpModal(): void;
};

export const SignInModal = ({
  isOpen,
  onClose,
  openSignUpModal,
}: SignInModalProps) => {
  const [isRememberMeChecked, setIsRememberMeChecked] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [message, setMessage] = useState('');
  const [error, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<Map<string, boolean>>(
    new Map([
      ['email', true],
      ['password', true],
    ])
  );
  const [isForgetPassword, setIsForgetPassword] = useState<boolean>(false);
  const [loginCall] = useMutation(DoLogin);
  const history = useHistory();
  const auth = useAuth();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // useEffect(() => {
  //     if (auth.userConfig.loginAttempt === 2){
  //         console.log(auth.userConfig.loginAttempt, "loginAttempt")
  //         setOpen(true);
  //     }
  // }, [auth.userConfig.loginAttempt])

  const calcIsDisabled = () =>
    !(
      Array.from(isDisabled.values()).filter((value) => !value).length ===
      Array.from(isDisabled.values()).length
    );
  const updateIsDisabled = (key: string) => (value: boolean) =>
    setIsDisabled((prev) => prev.set(key, value));

  const onSignUp = () => {
    openSignUpModal();
    onClose();
  };

  const textHandler =
    (state: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      state(event.target.value);

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsRememberMeChecked(event.target.checked);

  const login = async () => {
    setIsSubmitting(true);
    try {
      const loginResponse = await loginCall({
        variables: {
          emailid: email.toLowerCase().trim(),
          password,
        },
      });
      const {
        data: { doLogin },
        errors,
      } = loginResponse || {};

      if (doLogin) {
        auth.setUserConfig(doLogin);
        localStorage.setItem('userConfig', JSON.stringify(doLogin));
        const { companytype, loginAttempt, usertype } = doLogin;
        if (companytype === CompanyTypes.GSSA) {
          history.replace(loginAttempt === 0 ? '/' : '/quotes');
        } else if (
          companytype === CompanyTypes.ADMIN &&
          usertype === 'AWB Only'
        )
          history.replace('/abws');
        else if (companytype === CompanyTypes.ADMIN) history.replace('/admin');
        else {
          // setOpen(true);
          history.replace(loginAttempt === 1 ? '/' : '/getQuotes');
        }
      } else if (errors) {
        setMessage(errors.join(' '));
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
      setIsVisibleModal(true);
    }
  };

  const onForgetModal = () => {
    onClose();
    setIsForgetPassword(true);
  };

  return (
    <Fragment>
      <IntroOverlay open={open} handleClose={handleClose} />
      <AuthBaseModal isOpen={isOpen} onClose={onClose} title="Sign In">
        <Fragment>
          <Typography textAlign="center">
            Enter your email address to start
          </Typography>
          <TextInput
            value={email}
            onChange={textHandler(setEmail)}
            label="Email"
            validation="email"
            setIsDisabled={updateIsDisabled('email')}
          />
          <PasswordInput
            value={password}
            onChange={textHandler(setPassword)}
            setIsDisabled={updateIsDisabled('password')}
          />
          <CheckBoxWithLabel
            checked={isRememberMeChecked}
            onChange={handleRememberMe}
          >
            <Typography>Remember me</Typography>
          </CheckBoxWithLabel>
          <Stack width="100%">
            <SubmitButton
              onClick={login}
              isSubmitting={isSubmitting}
              text="Submit"
              isDisabled={calcIsDisabled()}
            />
            <BorderRadiusButton onClick={onForgetModal}>
              Forgot password?
            </BorderRadiusButton>
          </Stack>
          <Typography textAlign="center">
            Don't have an account?
            <BorderRadiusButton onClick={onSignUp}>Sign Up</BorderRadiusButton>
          </Typography>
        </Fragment>
      </AuthBaseModal>
      <ForgetPassword
        openSignUpModal={openSignUpModal}
        isOpen={isForgetPassword}
        onClose={() => setIsForgetPassword(false)}
      />
      <Snackbar
        className="SnackbarMSg"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
      <InfoModal
        open={isVisibleModal}
        header=""
        onClose={() => setIsVisibleModal(false)}
      >
        <Typography sx={{ color: 'rgba(5,21,81,0.9)', fontSize: '18px' }}>
          {error === 'Account Not Verified'
            ? 'Your account is awaiting verification. This takes up to 72 hours after registration. Thank you.'
            : error}
        </Typography>
      </InfoModal>
    </Fragment>
  );
};
