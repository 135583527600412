import styled from 'styled-components';

export const LandingContent = styled.div`
  position: relative;
  top: 117px;
  background: #ebedf4;
  height: calc(100vh - 116px);
  @media (max-width: 768px) {
    top: 81px;
  }
  @media (max-width: 425px) {
    top: 74px;
  }
  .landing_page_wrp {
    height: calc(100% - 69px);
    @media (max-width: 1199px) {
      height: calc(100% - 55px);
    }
    @media (max-width: 767px) {
      height: auto;
    }
    .location_map {
      text-align: center;
      position: relative;
      height: 100%;
      .lds-wrp {
        width: 100%;
        height: 100%;
        background: #e8e8e8 !important;
        @media (max-width: 1024px) {
          height: auto;
        }
        @media (max-width: 991px) {
          height: 100%;
          min-height: auto;
        }

        @media (max-width: 768px) {
          min-height: auto;
        }
        @media (max-width: 425px) {
          min-height: auto;
        }
        @media (max-width: 375px) {
          min-height: auto;
        }
        @media (max-width: 320px) {
          min-height: auto;
        }
        .banner_wrp {
          @media (max-width: 575px) {
            display: flex;
            flex-direction: column-reverse;
          }
        }
        .map_side_content {
          margin: 5% !important;
          @media (max-width: 768px) {
            margin: 0 25px;
            margin-bottom: 30px;
            width: 100%;
          }
          .header_content {
            font-size: 29px;
            font-weight: 800;
            color: #2d347a;
            @media (max-width: 991px) {
              font-size: 20px;
            }
          }
          .mid_section_content {
            margin-top: 2%;
            font-size: 24px;
            font-weight: 500;
            color: #2d347a;
            @media (max-width: 991px) {
              font-size: 18px;
              line-height: 25px;
            }
          }
          .main_content {
            margin-top: 5%;
            font-size: 16px;
            font-weight: 500;
          }
          text-align: left !important;
          font-family: Roboto !important;
          margin: 8% 14%;
          padding: 1.5rem 2.25rem;
          /* background: #e8e8e8 !important; */
          /* border: 1px solid #e0e0e0;
          box-shadow: 0px 10px 25px 0px #00000040;
          border-radius: 10px; */
        }
        .map_info_text {
          position: absolute;
          top: 10%;
          right: 23%;
          display: flex;
          /* text-transform: uppercase; */
          /* .map_button {
            width: 10px;
            height: 10px;
            background: #f5c003;
            margin: 6px !important;
          } */
        }
        .map_info {
          position: absolute;
          top: 10%;
          right: 3%;
          display: flex;
          text-transform: uppercase;
          .map_button {
            width: 10px;
            height: 10px;
            background: #f5c003;
            margin: 6px !important;
          }
        }
        .side_content-wrapper {
          // background: #dadada;
        }
        .lds-inner-wrp {
          // text-align: center;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // height: 100%;
          // min-height: 50vh;
          .lds-ripple {
            position: absolute;
            // width: 80px;
            // height: 80px;
            top: 50%;
            left: 65%;
            transform: translate(-50%, -50%);
            // z-index: 0;
            padding: 60px 0;
            > div {
              margin: 0 auto;
            }
            @media (max-width: 899px) {
              position: inherit;
              top: inherit;
              left: inherit;
              transform: inherit;
            }
            @media (max-width: 320px) {
              padding-top: 100px;
            }
          }
        }
      }
    }
    .solutions_slider {
      background-color: #051551;
      padding: 38px 0 59px 0;
      margin-bottom: 100px;

      .landing_title {
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 44px;
        color: #ffffff;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
      }
      .landing_border {
        width: 112px;
        margin: 0 auto;
        border: 2px solid #fdae15;
        background-color: #fdae15;
      }
      .landing_sub_title {
        align-items: center;
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #e0e0e0;
        width: 345px;
        margin: 0 auto;
        font-family: Heebo;
        white-space: pre-line;
        margin-top: 16px;
      }
      .solutions_items {
        padding-left: 92px;
        @media (max-width: 464px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        .react-multi-carousel-track {
          gap: 20px;
          .react-multi-carousel-item {
            margin-right: 35px;
            @media (max-width: 464px) {
              margin-right: 0;
            }
          }
        }
        div {
          div {
            div {
              div {
                div {
                  z-index: 0 !important;
                }
              }
            }
          }
        }
        .solutions_items_box {
          padding: 25px 20px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          height: 100%;
          .card_title {
            color: #000000;
            font-family: Heebo;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 15px;
          }
          .card_detail {
            color: #010a07;
            font-weight: 300;
            font-size: 14px;
            min-height: calc(100% - 106px);
            line-height: 20px;
            font-family: Heebo;
            font-style: normal;
            margin-bottom: 28px;
          }
          .card_button {
            border: 1px solid #ed1c24;
            border-radius: 25px;
            color: #ed1c24;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            padding: 12px 16px;
          }
        }
      }
    }
    .book_air_cargo {
      margin-bottom: 100px;
      .book_air_cargo_title {
        margin-bottom: 95px;
        .landing_title {
          font-family: Heebo;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 44px;
          color: #000000;
          text-align: center;
          width: fit-content;
          margin: 0 auto;
        }
        .landing_border {
          width: 112px;
          margin: 0 auto;
          border: 2px solid #fdae15;
          background-color: #fdae15;
        }
        .landing_sub_title {
          align-items: center;
          text-align: center;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #010a07;
          width: 345px;
          margin: 0 auto;
          font-family: Heebo;
          white-space: pre-line;
          margin-top: 16px;
        }
      }
      .book_air_cargo_tabs {
        margin: 0 75px;
        @media (max-width: 575px) {
          margin: 0 15px;
        }
        .tab_image {
          img {
            width: 100%;
          }
        }
        .tabs_items {
          border-bottom: 5px solid #051551;
          margin-bottom: 100px;
          @media (max-width: 575px) {
            margin-bottom: 30px;
          }
          div {
            div {
              justify-content: center;
              @media (max-width: 575px) {
                flex-wrap: wrap;
              }
            }
          }

          button {
            background: #c4c4c4;
            border-radius: 25px 25px 0px 0px;
            color: #000000;
            min-width: 303px;
            margin-bottom: -1px;
            margin: 0 15px;
            justify-content: flex-end;
            font-size: 20px;
            padding: 8px 0;
            color: #7f7f7f;
            @media (max-width: 1199px) {
              min-width: 160px;
              font-size: 16px;
            }
            @media (max-width: 767px) {
              min-width: 130px;
              font-size: 14px;
              margin: 0 4px;
              padding: 8px 0;
            }
            @media (max-width: 575px) {
              width: 100%;
              border-radius: 0;
              border-top: 1px solid #0a1551;
              min-height: auto;
              padding: 12px 0;
            }
          }

          .Mui-selected {
            background-color: #051551;
            color: #fff !important;
          }
          span {
            display: none;
          }
        }
        .tab_title {
          color: #000000;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          font-family: Heebo;
          margin-bottom: 50px;
        }
        .tab_box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .tab_inner_list {
            margin-left: 25px;
            .tab_list_text {
              color: #333333;
              font-family: Heebo;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 20px;
              position: relative;
            }
            .tab_list_text:after {
              content: '';
              height: 9px;
              width: 9px;
              background-color: #ed1c24;
              display: block;
              position: absolute;
              left: -20px;
              top: 5px;
            }
          }
          .tab_buttons {
            button {
              color: #828282;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              border: 1px solid #bdbdbd;
              box-sizing: border-box;
              border-radius: 10px;
              padding: 12px 9px;
              margin-right: 8px;
            }
            button:hover {
              color: #ed1c24;
              border: 1px solid #ed1c24;
            }
          }
        }
      }
    }
    .airlines_cargo {
      background: #ffffff;
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      margin: 0 75px 55px 75px;
      padding: 56px 39px;
      @media (max-width: 1199px) {
        margin: 0 15px 55px 15px;
      }
      .airlines_cargo_slider {
        div {
          z-index: 0;
        }
      }
      .airlines_cargo_title {
        color: #010a07;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 30px;
        line-height: 29px;
      }
      .airlines_cargo_text {
        color: #333333;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .airlines_cargo_slider {
      .react-multi-carousel-item {
        margin-right: 20px;
      }
      .airlines_cargo_box {
        width: 100%;
        border-radius: 10px;
        height: 144px;
        background: #051551;
        display: flex;
        color: #f2f2f2;
        font-size: 24px;
        line-height: 35px;
        align-items: center;
        justify-content: center;
        .airlines_cargo_card_title {
        }
      }
    }
  }
`;
