import { Box, Typography } from '@mui/material';
import {
  InfoModalContainer,
  InfoStyledModal,
} from 'src/Components/InfoModal/style';
import { InfoModalProps } from './types';
import Close from 'src/Assets/Images/Close.png';
import { useState } from 'react';

const InfoModal = (props: InfoModalProps) => {
  const {
    open,
    body,
    onClose,
    header,
    children,
    className = 'confirm_modal',
  } = props;

  const [someState, setSomeState] = useState<any>(open);

  const handleClose = () => {
    setSomeState(false);
    onClose();
  };

  return (
    <Box>
      <InfoStyledModal
        sx={{ overflow: 'auto' }}
        open={someState}
        onClose={handleClose}
      >
        <InfoModalContainer>
          <Box
            style={{
              maxWidth: 500,
              border: '3px solid black',
              borderRadius: '20px',
            }}
            className={`modal ${className}`}
          >
            {/* {header && ( */}
            <Box className="modalHeader">
              <Typography className="headerText">{header}</Typography>

              <Box
                sx={{ cursor: 'pointer', display: 'flex' }}
                onClick={handleClose}
              >
                <img className="closeIcon" src={Close} alt="Close" />
                <Typography className="closeText">close</Typography>
              </Box>
            </Box>
            {/* )} */}
            {body && (
              <Box className="modalBody">
                <Typography color="text.secondary">{body}</Typography>
              </Box>
            )}
            {children && <Box className="modalBody">{children}</Box>}
            <Box className="modalFooter"></Box>
          </Box>
        </InfoModalContainer>
      </InfoStyledModal>
    </Box>
  );
};
export default InfoModal;
