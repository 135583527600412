const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.34174 7.1054C8.32816 7.09185 8.31738 7.07576 8.31003 7.05804C8.30268 7.04032 8.2989 7.02133 8.2989 7.00215C8.2989 6.98297 8.30268 6.96397 8.31003 6.94626C8.31738 6.92854 8.32816 6.91244 8.34174 6.8989L13.7452 1.49606C13.9092 1.33176 14.0013 1.10901 14.0011 0.876821C14.001 0.644628 13.9086 0.422009 13.7443 0.25794C13.58 0.0938704 13.3572 0.00178925 13.125 0.00195334C12.8928 0.00211744 12.6702 0.0945133 12.5062 0.258815L7.10332 5.65931C7.08978 5.6729 7.07368 5.68367 7.05597 5.69102C7.03825 5.69837 7.01926 5.70216 7.00007 5.70216C6.98089 5.70216 6.9619 5.69837 6.94418 5.69102C6.92646 5.68367 6.91037 5.6729 6.89682 5.65931L1.49399 0.258815C1.41275 0.177538 1.3163 0.113057 1.21014 0.0690558C1.10398 0.0250542 0.990197 0.00239289 0.87528 0.00236581C0.643196 0.00231111 0.420596 0.0944537 0.256449 0.258523C0.0923022 0.422593 5.47224e-05 0.64515 2.43387e-08 0.877234C-5.46738e-05 1.10932 0.0920879 1.33192 0.256157 1.49606L5.65841 6.8989C5.67199 6.91244 5.68276 6.92854 5.69011 6.94626C5.69747 6.96397 5.70125 6.98297 5.70125 7.00215C5.70125 7.02133 5.69747 7.04032 5.69011 7.05804C5.68276 7.07576 5.67199 7.09185 5.65841 7.1054L0.256157 12.5088C0.174918 12.5901 0.110484 12.6866 0.0665321 12.7928C0.0225806 12.8989 -2.70594e-05 13.0127 2.43387e-08 13.1276C5.47224e-05 13.3597 0.0923022 13.5823 0.256449 13.7464C0.337726 13.8276 0.434209 13.892 0.540388 13.936C0.646568 13.9799 0.760364 14.0025 0.87528 14.0025C1.10736 14.0025 1.32992 13.9102 1.49399 13.7461L6.89682 8.34265C6.91037 8.32907 6.92646 8.31829 6.94418 8.31094C6.9619 8.30359 6.98089 8.2998 7.00007 8.2998C7.01926 8.2998 7.03825 8.30359 7.05597 8.31094C7.07368 8.31829 7.08978 8.32907 7.10332 8.34265L12.5062 13.7461C12.6702 13.9102 12.8928 14.0025 13.1249 14.0025C13.357 14.0026 13.5796 13.9104 13.7437 13.7464C13.9078 13.5823 14.0001 13.3597 14.0001 13.1276C14.0002 12.8956 13.9081 12.673 13.744 12.5088L8.34174 7.1054Z"
        fill="#9093BB"
      />
    </svg>
  );
};
export default CloseIcon;
