import React, { createContext, useContext, useState } from 'react';

export interface MarkupUpdateContextType {
  selectedIds: number[];
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  disselectedIds: number[];
  setDisselectedIds: React.Dispatch<React.SetStateAction<number[]>>;
}

type WithChildren = {
  children?: React.ReactNode;
};

const defaultValue: MarkupUpdateContextType = {
  selectedIds: [],
  setSelectedIds: () => {},
  selectAll: false,
  setSelectAll: () => false,
  disselectedIds: [],
  setDisselectedIds: () => [],
};

const SelectedIdsContext = createContext<MarkupUpdateContextType>(defaultValue);

export const useSelectedIds = () => {
  return useContext(SelectedIdsContext);
};

const SelectedIdsProvider: React.FC<WithChildren> = ({ children }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [disselectedIds, setDisselectedIds] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);

  return (
    <SelectedIdsContext.Provider
      value={{
        selectedIds,
        setSelectedIds,
        selectAll,
        setSelectAll,
        disselectedIds,
        setDisselectedIds,
      }}
    >
      {children}
    </SelectedIdsContext.Provider>
  );
};

export { SelectedIdsContext, SelectedIdsProvider };

export function useIdsContext() {
  return useContext(SelectedIdsContext);
}
