import { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Divider,
  Typography,
  Button,
} from '@mui/material';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { CustomDrawer, Navbar, DrawerHeader, LogoutBtn } from './styled';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from 'src/hooks';
import Logouticon from 'src/Assets/Images/Logouticon.png';
import { CompanyTypes, getUserHeaders } from 'src/Utils/UserPermission';
import moment from 'moment';
import { IntroOverlay } from 'src/Components/IntroOverlay';

const DrawerComponent = (props) => {
  const { Logobrand, userConfigStr, doLogout } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const auth = useAuth();
  const authCompanyType = auth?.userConfig?.companytype;
  const authUserType = auth?.userConfig?.usertype;
  const authUserLevel = auth?.userConfig?.userlevel;
  const userName = `${auth?.userConfig?.firstName} ${auth?.userConfig?.surName}`;
  const history = useHistory();
  const companyName = auth?.userConfig?.companyname;

  const manageAccountHandler = () => {
    if (authCompanyType === CompanyTypes.GSSA) {
      history.push('/manage-account');
    } else if (authCompanyType === CompanyTypes.FORWARDER) {
      history.push('/profile');
    }
  };

  return (
    <>
      <IntroOverlay open={open} handleClose={handleClose} />
      <Navbar>
        <Box className="outerBox">
          <Box className="drawerBox">
            <Link to="/getQuote">
              <img
                className="brandsection-logo"
                alt="Logobrand"
                src={Logobrand}
              />
            </Link>
          </Box>
          <Box className="navbarWrapper">
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon />
            </IconButton>
            <CustomDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
            >
              <Box className="drawerHeader">
                <Box className="logoSection">
                  <Link to="/getQuote">
                    <img
                      className="brandsection-logo"
                      alt="Logobrand"
                      src={Logobrand}
                    />
                  </Link>
                </Box>
                <IconButton onClick={() => setOpenDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <List>
                <Box className="brandsection-links">
                  {userConfigStr ? (
                    <>
                      <Box
                        className="profileWrapper"
                        onClick={manageAccountHandler}
                      >
                        {authCompanyType !== CompanyTypes.ADMIN && (
                          <img
                            className="userDrawerImage"
                            src={`/images/gssa/${companyName.replaceAll(
                              ' ',
                              '_'
                            )}.png?${moment().format()}`}
                            onError={(e: any) => {
                              e.target.style.display = 'none';
                            }}
                            onClick={manageAccountHandler}
                            alt={companyName}
                          />
                        )}
                        <Typography>{userName}</Typography>
                      </Box>
                      {authCompanyType &&
                        getUserHeaders(
                          authCompanyType,
                          authUserType,
                          authUserLevel
                        ).map(({ path, header }) => (
                          <ListItem
                            key={path}
                            onClick={() => setOpenDrawer(false)}
                          >
                            <ListItemText>
                              <NavLink
                                to={header === 'Flights' ? '#' : path}
                                className={
                                  header !== 'Flights'
                                    ? 'navLink'
                                    : 'navLinkFlight'
                                }
                              >
                                {header}
                              </NavLink>
                            </ListItemText>
                          </ListItem>
                        ))}
                      {authCompanyType !== CompanyTypes.ADMIN && (
                        <Button
                          sx={{
                            color: '#828380',
                            fontWeight: '500',
                            fontSize: '16px',
                          }}
                          onClick={() => handleOpen()}
                        >
                          Help
                        </Button>
                      )}
                      <Divider orientation="horizontal" />
                      <ListItem className="logoutbtnwrap">
                        <LogoutBtn
                          className="logoutbtn_mobile"
                          onClick={doLogout}
                        >
                          <img alt="logouticon" src={Logouticon} />
                          <Typography>Logout</Typography>
                        </LogoutBtn>
                      </ListItem>
                    </>
                  ) : (
                    <DrawerHeader className="navbarRight">
                      <Box className="header_menu"></Box>
                      <Divider orientation="horizontal" />
                      <Box className="btnHeader">
                        <Button
                          className="btnSignin"
                          onClick={() => history.push('/signin')}
                        >
                          Sign in
                        </Button>
                        <Button
                          className="btnSignup"
                          onClick={() => history.push('/signUp')}
                        >
                          Sign up
                        </Button>
                      </Box>
                    </DrawerHeader>
                  )}
                </Box>
              </List>
            </CustomDrawer>
          </Box>
        </Box>
      </Navbar>
    </>
  );
};

export default DrawerComponent;
