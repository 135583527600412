import { Modal } from '@mui/material';
import styled from 'styled-components';

export const InfoModalContainer = styled.div`
  .warn_modal_mobile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 500px;
    background: #ffffff;
    border: 1px solid #ebecf4;
    box-sizing: border-box;
    box-shadow: 2px 2px 20px rgba(8, 10, 42, 0.09);
    border-radius: 5px;
    margin: 1.75rem auto;
    outline: 0;
    .modalHeader {
      padding: 22px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #051551;
      font-weight: 500;
      font-size: 18px;
      .headerText {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #333333;
      }
      .closeIcon {
        object-fit: contain;
      }
      .closeText {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }
    }
    .modalBody {
      padding: 25px 30px;
      max-height: calc(100vh - 175px);
      overflow: auto;
    }

    .modalFooter {
      border-top: none;
      padding: 16px 30px;
      text-align: left;
      display: flex;
      justify-content: flex-end;
      .cancel_btn {
        background-color: #fff;
        border-radius: 25px;
        color: #828282;
        font-size: 14px;
        font-family: 'Roboto';
        padding: 10px 53px;
        text-transform: capitalize;
        display: flex;
        border: 1px solid #e0e0e0;
        font-weight: 600;
        @media (max-width: 575px) {
          width: 100%;
          margin-left: 0;
        }
      }
      .loading_btn {
        background-color: #ed1c24;
        border-radius: 25px;
        color: #ffffff;
        font-family: 'Roboto';
        font-size: 14px;
        padding: 10px 53px;
        text-transform: capitalize;
        display: flex;
        border: 1px solid #ed1c24;
        font-weight: 600;
        margin-left: 22px;
        min-width: 159px;
        @media (max-width: 575px) {
          width: 100%;
          margin-left: 0;
        }
      }
      .disabled_btn {
        background-color: #dcdcdc;
        border-radius: 25px;
        color: #ffffff;
        font-family: 'Roboto';
        font-size: 14px;
        padding: 10px 53px;
        text-transform: capitalize;
        display: flex;
        border: 1px solid #dcdcdc;
        font-weight: 600;
        margin-left: 22px;
        min-width: 159px;
        @media (max-width: 575px) {
          width: 100%;
          margin-left: 0;
        }
      }
      .outer_loader {
        height: 14px !important;
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      .delete_btn {
        background-color: #ed1c24;
        border-radius: 25px;
        color: #ffffff;
        font-family: 'Roboto';
        font-size: 14px;
        padding: 10px 53px;
        text-transform: capitalize;
        display: flex;
        border: 1px solid #ed1c24;
        font-weight: 600;
        margin-left: 22px;
        min-width: 159px;
        @media (max-width: 575px) {
          width: 100%;
          margin-left: 0;
        }
      }
    }
    @media (max-width: 550px) {
      .warn_modal_mobile {
        width: 90%;
      }
    }
  }
`;

export const InfoStyledModal = styled(Modal)({
  '& .MuiBackdrop-root': {
    background: 'rgba(68,80,124,0.80) !important',
  },
});
