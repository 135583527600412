import { useEffect, useState } from 'react';
import {
  FormControl,
  Box,
  Button,
  ListItem,
  List,
  MenuItem,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  Modal,
  ListItemText,
} from '@mui/material';
import Logouticon from 'src/Assets/Images/Logouticon.png';
import Logobrand from 'src/Assets/Images/triagobrand.png';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { Navbar, StyledMenu } from './styled';
import { NavLink } from 'react-router-dom';
import DrawerComponent from 'src/Components/Header/DrawerComponent';
import { CompanyTypes, getUserHeaders } from 'src/Utils/UserPermission';
import { FlagIcon } from 'react-flag-kit';
import { IntroOverlay } from 'src/Components/IntroOverlay';
import WarnModel from 'src/Components/WarnModel';
import { GroupCountriesModal } from '../GroupCountriesModal/GroupCountriesModal';
import { SignInModal } from '../Auth/SignIn';
import { SignUpModal } from '../Auth/SignUp';

const Header = (props) => {
  const { value, setValue } = props;
  const history = useHistory();
  const auth = useAuth();
  const userConfigStr = localStorage.getItem('userConfig');
  const userConfig = userConfigStr ? JSON.parse(userConfigStr) : {};
  const companyType = String(userConfig.companytype);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authCompanyType = auth?.userConfig?.companytype;
  const authUserType = auth?.userConfig?.usertype;
  const authUserLevel = auth?.userConfig?.userlevel;
  const countryCode = auth?.userConfig?.countrycode;
  const userid = auth?.userConfig?.userid;
  const sessionTimeout = localStorage.getItem('sessionTimeout');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [open, setOpen] = useState(false);
  const [isWarnVisible, setIsWarnVisible] = useState<boolean>(false);
  const [toolTipInfoData, setToolTipInfoData] = useState<any>();

  const { getToolTip, toolTipData } = props;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const doLogout = () => {
    history.replace('/');
    localStorage.clear();
    auth.setUserConfig(null);
  };

  useEffect(() => {
    if (window.location.pathname !== '/manage-account') {
      localStorage.removeItem('selectedTab');
    }
    setIsWarnVisible(true);
  }, []);

  useEffect(() => {
    setTimeout(() =>
      getToolTip({
        variables: {
          userId: userid,
        },
      })
    );
  }, []);
  useEffect(() => {
    if (toolTipData.data?.getToolTip) {
      const parsedToolTipData = JSON.parse(toolTipData.data.getToolTip);
      setToolTipInfoData(parsedToolTipData);
    } else if (toolTipData.data?.getToolTipForwarder) {
      const parsedToolTipData = JSON.parse(
        toolTipData.data.getToolTipForwarder
      );
      setToolTipInfoData(parsedToolTipData);
    }
  }, [toolTipData]);

  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false);
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false);

  return (
    <>
      <SignInModal
        isOpen={isOpenSignInModal}
        onClose={() => setIsOpenSignInModal(false)}
        openSignUpModal={() => setIsOpenSignUpModal(true)}
      />
      <SignUpModal
        isOpen={isOpenSignUpModal}
        onClose={() => setIsOpenSignUpModal(false)}
        onOpen={() => setIsOpenSignUpModal(true)}
      />
      <IntroOverlay open={open} handleClose={handleClose} />
      {(!sessionTimeout || sessionTimeout === null) && (
        <WarnModel
          open={isWarnVisible}
          header=""
          onClose={() => setIsWarnVisible(false)}
        >
          <Typography
            sx={{ color: 'rgba(5,21,81,0.9)', fontSize: '18px' }}
          >{`This site is maximised for use in landscape mode only on tablets. Please turn your tablet around to obtain the best experience`}</Typography>
        </WarnModel>
      )}
      <Navbar>
        <Box className="navbarWrapper">
          {isMobile ? (
            <DrawerComponent
              Logobrand={Logobrand}
              userConfigStr={userConfigStr}
              companyType={companyType}
              value={value}
              setValue={setValue}
              doLogout={doLogout}
            />
          ) : (
            <>
              <Box className="brandsection">
                <Link
                  to={
                    authCompanyType === CompanyTypes.GSSA
                      ? '/quotes'
                      : '/getQuote'
                  }
                >
                  <img
                    className="brandsection-logo"
                    alt="Logobrand"
                    src={Logobrand}
                  />
                </Link>

                <Box className="brandsection-links">
                  {userConfigStr ? (
                    <>
                      {authCompanyType &&
                        getUserHeaders(
                          authCompanyType,
                          authUserType,
                          authUserLevel
                        )?.map((Nav, index) => {
                          if (
                            Nav.header === 'Quotes' &&
                            authCompanyType === CompanyTypes.GSSA &&
                            toolTipInfoData &&
                            toolTipInfoData?.message.unread_bookings
                          ) {
                            return (
                              <NavLink
                                style={{ position: 'relative' }}
                                key={index}
                                to={Nav.path}
                                className={'navLink'}
                              >
                                {Nav.header}
                                <Box
                                  sx={{
                                    background: 'rgba(5,21,81,0.9)',
                                    width: '14px',
                                    height: '14px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      textAlign: 'center',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {toolTipInfoData?.message.unread_bookings}
                                  </Typography>
                                </Box>
                              </NavLink>
                            );
                          } else if (
                            Nav.header === 'Quotes' &&
                            authCompanyType === CompanyTypes.FORWARDER &&
                            toolTipInfoData &&
                            toolTipInfoData?.message?.unread_quotes
                          ) {
                            return (
                              <NavLink
                                style={{ position: 'relative' }}
                                key={index}
                                to={Nav.path}
                                className={'navLink'}
                              >
                                {Nav.header}
                                <Box
                                  sx={{
                                    background: 'rgba(5,21,81,0.9)',
                                    width: '14px',
                                    height: '14px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      textAlign: 'center',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {toolTipInfoData?.message?.unread_quotes}
                                  </Typography>
                                </Box>
                              </NavLink>
                            );
                          } else if (
                            Nav.header === 'Shipments' &&
                            (authCompanyType === CompanyTypes.GSSA ||
                              authCompanyType === CompanyTypes.FORWARDER) &&
                            toolTipInfoData &&
                            toolTipInfoData?.message.unread_shipments
                          ) {
                            return (
                              <NavLink
                                style={{ position: 'relative' }}
                                key={index}
                                to={Nav.path}
                                className={'navLink'}
                              >
                                {Nav.header}
                                <Box
                                  sx={{
                                    background: 'rgba(5,21,81,0.9)',
                                    width: '14px',
                                    height: '14px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      textAlign: 'center',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {toolTipInfoData.message.unread_shipments}
                                  </Typography>
                                </Box>
                              </NavLink>
                            );
                          }
                          return (
                            <NavLink
                              key={index}
                              to={Nav.header === 'Flights' ? '#' : Nav.path}
                              className={
                                Nav.header !== 'Flights'
                                  ? 'navLink'
                                  : 'navLinkFlight'
                              }
                            >
                              {Nav.header}
                            </NavLink>
                          );
                        })}
                      {companyType !== CompanyTypes.ADMIN && (
                        <Button
                          sx={{
                            color: '#828380',
                            marginLeft: '24px',
                            fontWeight: '500',
                            fontSize: '16px',
                          }}
                          onClick={() => handleOpen()}
                        >
                          Help
                        </Button>
                      )}
                    </>
                  ) : (
                    <Box className="navbarRight"></Box>
                  )}
                </Box>
              </Box>
              <Box className="navbarRight">
                {userConfigStr ? (
                  <>
                    {authCompanyType === CompanyTypes.ADMIN &&
                      authUserType === 'MASTER' && (
                        <Button
                          sx={{
                            color: '#828380',
                            fontWeight: '500',
                            fontSize: '16px',
                            width: '200px',
                          }}
                          onClick={() => setOpenDropDown((prev) => !prev)}
                        >
                          Group countries
                        </Button>
                      )}
                    <GroupCountriesModal
                      isOpen={openDropDown}
                      setIsOpen={setOpenDropDown}
                    />
                    <FlagIcon
                      className="flag_image"
                      size={50}
                      code={countryCode?.toString()}
                    />
                    <List className="navbarul">
                      <ListItem className="selectMenu">
                        <FormControl>
                          {userConfig?.firstName} {userConfig?.surName}
                        </FormControl>
                        <StyledMenu
                          open={false}
                          id="demo-customized-menu"
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}
                        >
                          <MenuItem disableRipple>Edit</MenuItem>
                          <MenuItem disableRipple>Duplicate</MenuItem>
                          <MenuItem disableRipple>Archive</MenuItem>
                          <MenuItem disableRipple>More</MenuItem>
                        </StyledMenu>
                      </ListItem>
                      <Divider orientation="vertical" />
                      <ListItem className="logoutbtnwrap">
                        <Box className="logoutbtn" onClick={doLogout}>
                          <img alt="logouticon" src={Logouticon} />
                          <Typography>Logout</Typography>
                        </Box>
                      </ListItem>
                    </List>
                  </>
                ) : (
                  <Box className="btnHeader">
                    <Button
                      className="btnSignin"
                      onClick={() => setIsOpenSignInModal(true)}
                    >
                      Sign in
                    </Button>
                    <Button
                      className="btnSignup"
                      onClick={() => setIsOpenSignUpModal(true)}
                    >
                      Sign up
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Navbar>
    </>
  );
};

export default Header;
