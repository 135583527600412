import { useEffect, useState } from 'react';
import Header from 'src/Components/Header';
import { Box, Typography } from '@mui/material';
import { VerifyContent } from 'src/Pages/Verify/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DoVerification } from 'src/Reducers/mutations';
import ReactLoading from 'react-loading';
import { GetToolTip } from 'src/Reducers/queries';

const Verify = () => {
  const location = useLocation<any>();
  const history = useHistory();

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('Loading...');
  const [doVerify] = useMutation<any>(DoVerification);

  useEffect(() => {
    const paths = (location?.pathname).split('/');
    const token: any = paths[2];
    doVerify({
      variables: {
        token: token,
      },
    })
      .then(({ data }) => {
        if (data?.doVerification[0]?.isVerified) {
          const msg = data?.doVerification[0]?.message;
          const isCorrect = data?.doVerification[0]?.isVerified;
          setMessage(msg);
          setIsLoading(false);
          setIsVerified(isCorrect);
        } else {
          setIsLoading(false);
          setMessage(
            'Sorry, an error has occurred. Please take a screenshot and email it to wayne@tricargo.aero. This will help us to resolve this as soon as possible and we will also respond to assist you. Thank you.!'
          );
        }
      })
      .catch(() => {
        setMessage(
          'Sorry, an error has occurred. Please take a screenshot and email it to wayne@tricargo.aero. This will help us to resolve this as soon as possible and we will also respond to assist you. Thank you.!'
        );
        setIsLoading(false);
      });
  }, [doVerify, location?.pathname]);
  const [getToolTip, toolTipData] = useLazyQuery(GetToolTip);
  return (
    <>
      <Header getToolTip={getToolTip} toolTipData={toolTipData} />
      <VerifyContent>
        <Box className="verify_page_wrp">
          <Box className="user_verification">
            <Box className="content_wrapper">
              <Box className="message_wrapper">
                {isLoading ? (
                  <Box className="outer_loader">
                    <ReactLoading type={'bars'} color="#3f83f1" />
                  </Box>
                ) : isVerified ? (
                  <Typography
                    variant="h1"
                    className="message"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                ) : (
                  <Typography variant="h1" className="error_message">
                    {message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="footer">
          <Typography className="copyrights_text">
            &#169; Copyright 2022 tricargo.aero
          </Typography>
          <Box className="right_content">
            <Box>
              <img
                onClick={() => history.push('/')}
                src="https://i2.paste.pics/b065105a3802eaf50ebdc08c7499ce46.png"
                alt="social_media"
              />
              <img
                style={{ marginLeft: '5px' }}
                onClick={() => history.push('/')}
                src="https://i2.paste.pics/469e8810e4da16f384269100e73cbf45.png"
                alt="social_media"
              />
              <img
                style={{ marginLeft: '5px' }}
                src="https://i2.paste.pics/d442ea775371aa79430aa23e38344496.png"
                alt="social_media"
                onClick={() => history.push('/')}
              />
            </Box>
          </Box>
        </Box>
      </VerifyContent>
    </>
  );
};

export default Verify;
