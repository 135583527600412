import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *::-webkit-scrollbar {
      width: 0.4em;
      height: 4px;
    }
    *::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    }
    *::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.4);
    }
    .flex {
      display: flex;
    }
    .items-center {
      align-items: center;
    }
    .MuiDataGrid-columnHeaderTitle {
      font-family: Roboto;
    }
    .MuiDataGrid-cell--withRenderer {
      font-family: Roboto;
      .MuiBox-root{
        font-family: Roboto;
      }
    }
    .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        background-color: #FDAE15;
        color: #000000;
      }
   }
    .svgMap-map-wrapper{
      background: transparent !important;
      cursor:pointer;
      margin-top:5%;
      font-family: Heebo !important;
      font-weight:500;
    }
    .svgMap-tooltip{
      background:transparent !important;
      box-shadow:none;
      border:none;
      pointer-events:all;
    }
    .svgMap-tooltip-content-container{
      width:18vw;
      padding:0 !important
    }
    .svgMap-tooltip-content-outer{
      background:#F2F2F2;
      padding:5%;
      border-radius:5px;
    }
    .svgMap-tooltip-title{
      background:#051551;
      width:223px;
      padding: 5% 10% !important;
      text-align:left !important;
      border-radius:5px;
      width:100%;
      color:#fff;
      margin:1% 0;
      overflow:hidden;
      text-overflow: ellipsis;
      font-family: Heebo !important;
    }
    .svgMap-tooltip.svgMap-tooltip-flipped{
      border: none;
    }
    .svgMap-map-controls-wrapper{
      z-index:0 !important;
    }
    .svgMap-tooltip-content{
      margin-top:20px;
      overflow-x:hidden;  
      max-height:30vh;
      overflow-y:auto;
      text-overflow: ellipsis;
      ::-webkit-scrollbar {
        width: 3px !important;
        background:#C4C4C4;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #ED1C24; 
        width:5px;
        border-radius: 10px;
      background-clip: content-box;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
      }
    }
    .svgMap-tooltip-content div{
      background:#f2f2f2;
      border-radius:5px;
      width:15vw !important;
      padding:5% 5%;
      margin:0.1% 0;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    .svgMap-map-wrapper .svgMap-map-controls-wrapper {
      display:none;
    }
    .svgMap-tooltip .svgMap-tooltip-content table td:first-child {
      padding:0
  }
  @media(min-width:1024px){
    .svgMap-map-wrapper{
      margin-top: 9%;
    }
  }
  @media(max-width:1024px){
    .svgMap-map-wrapper{
      padding-top: 80%;
    }
    .svgMap-map-wrapper{
      margin-top: 9%;
    }
  }
    @media(max-width:991px){
      .svgMap-tooltip-content-container{
        width:30vw;
      }
      .sc-jRQBWg{
        top: 70px !important;
      }
      .svgMap-tooltip-content div{
        background:#f2f2f2;
        border-radius:5px;
        width:30vw !important;
        padding:5% 5%;
        margin:0.1% 0;
      }
    }
    
    @media(max-width:712px){
      .svgMap-tooltip-content-container{
        width:30vw;
      }

    }
    @media(max-width:899px){
      .svgMap-container{
        height: 390px;
        overflow: hidden;
      }
      #svgMap{
        height: 390px;
      }
      .svgMap-map-wrapper{
        padding-top: 50%;
      }
    }
    @media(max-width:768px){
      .sc-jRQBWg{
        top: 106px !important;
      }
      .svgMap-map-wrapper{
        min-height: 452px;
        padding-top: 0;
        
      }
     
    }
    @media(max-width:425px){
      .sc-jRQBWg{
        top: 104px !important;
      }
      .svgMap-map-wrapper{
      .svgMap-map-image{
        top: -15%;
      }
    }
      @media(max-width:320px){
        top: 107px;
      }
    }
    // :has(.warn_modal_mobile) body[style="overflow: hidden;"]{
    //   padding-right: 0 !important;
    //   overflow: auto !important;
    // }
    .MuiTooltip-tooltip:has(.chargestooltip) {    
      min-width:350px !important;
    }
    body{
      overflow-y: auto !important;
    } 

`;

export default GlobalStyle;
