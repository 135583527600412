import { createContext } from 'react';

interface ErrorHandler {
  msgConfig: any;
  setMsgConfig: any;
}

export const errorHandlerContext = createContext({
  msgConfig: null,
  setMsgConfig: null,
} as ErrorHandler);
