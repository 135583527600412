import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: ['Heebo', ' sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#051551',
    },
    secondary: {
      main: '#e50046',
    },
  },
  components: {
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          disableScrollLock: true,
        },
      },
    },
  },
});
