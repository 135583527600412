import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import ReactLoading from 'react-loading';
import useId from '@mui/material/utils/useId';
import * as Yup from 'yup';
import MuiPhoneNumber from 'mui-phone-number';
import { useQuery } from '@apollo/client';
import { GetCountries } from 'src/Reducers/queries';
import CloseIcon from '@mui/icons-material/Close';
import {
  AirlineButtonStyled,
  CheckboxLabel,
  CheckBoxStack,
  EyeBox,
  InputStack,
  LinkButton,
  ModalBackground,
  SubmitButtonStyled,
} from './styled';
import { CloseButton } from '../AuthBaseModal/styled';

export type PasswordInputProps = {
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  setIsDisabled(value: boolean): void;
  label?: string;
};

export const PasswordInput = ({
  value,
  onChange,
  setIsDisabled,
  label,
}: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true);
  const schema = useMemo(
    () => Yup.string().min(8, 'Password must be at least 8 characters'),
    []
  );
  const [error, setError] = useState('');

  useEffect(() => {
    schema
      .validate(value)
      .then(() => {
        setError('');
        setIsDisabled(false);
      })
      .catch(() => {});
  }, [value, schema, setIsDisabled]);

  const onBlur = () => {
    schema
      .validate(value)
      .then(() => {
        setError('');
      })
      .catch((err: any) => {
        setError(err.message);
        setIsDisabled(true);
      });
  };

  return (
    <InputStack>
      <TextField
        onBlur={onBlur}
        variant="outlined"
        label={label ? label : 'Password'}
        type={isPasswordVisible ? 'password' : 'text'}
        onChange={onChange}
        value={value}
      />
      <EyeBox className="eye-icon">
        {isPasswordVisible ? (
          <VisibilityOffIcon
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        ) : (
          <VisibilityIcon
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        )}
      </EyeBox>
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </InputStack>
  );
};

export type AuthInputProps = {
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  label: string;
  validation: 'string' | 'email';
  setIsDisabled(value: boolean): void;
};

export const TextInput = ({
  value,
  onChange,
  label,
  validation,
  setIsDisabled,
}: AuthInputProps) => {
  const [error, setError] = useState('');
  const schema = useMemo(
    () =>
      validation === 'string'
        ? Yup.string()
            .required('Field is required')
            .max(71, 'Maximum length is 70 characters')
        : Yup.string()
            .email('Invalid email')
            .required('email is required')
            .max(71, 'Maximum length is 70 characters'),
    [validation]
  );

  useEffect(() => {
    schema
      .validate(value)
      .then(() => {
        setError('');
        setIsDisabled(false);
      })
      .catch(() => {});
  }, [value, schema, setIsDisabled]);

  const onBlur = () => {
    schema
      .validate(value)
      .then(() => {
        setError('');
      })
      .catch((err: any) => {
        setError(err.message);
        setIsDisabled(true);
      });
  };

  return (
    <InputStack>
      <TextField
        onBlur={onBlur}
        variant="outlined"
        label={label}
        type="email"
        onChange={onChange}
        value={value}
      />
      {label === 'Full Company Name (70 characters max)' ? (
        <h6
          style={{
            fontSize: '12px',
            fontWeight: 'normal',
            color: '#6c757d',
            margin: '0',
          }}
        >
          Number characters used = {value.length}
        </h6>
      ) : (
        <></>
      )}
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </InputStack>
  );
};

export type SubmitButtonProps = {
  onClick(): void;
  text: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
};

export const SubmitButton = ({
  onClick,
  text,
  isSubmitting = false,
  isDisabled = false,
}: SubmitButtonProps) => (
  <SubmitButtonStyled onClick={onClick} disabled={isDisabled}>
    {isSubmitting ? (
      <ReactLoading type={'bars'} height="30px" width="30px" color="#FFFFFF" />
    ) : (
      text
    )}
  </SubmitButtonStyled>
);

export type CheckBoxWithLabelProps = {
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  checked: boolean;
  children?: React.ReactNode;
};

export const CheckBoxWithLabel = ({
  checked,
  onChange,
  children,
}: CheckBoxWithLabelProps) => {
  const id = useId();
  return (
    <CheckBoxStack>
      <Checkbox id={id} checked={checked} onChange={onChange} />
      <CheckboxLabel htmlFor={id}>{children}</CheckboxLabel>
    </CheckBoxStack>
  );
};

export type TermAndPoliscyProps = {
  text: string;
};

export const TermAndPoliscy = ({ text }: TermAndPoliscyProps) => (
  <LinkButton
    href="https://tricargo-images-bucket.s3.eu-west-1.amazonaws.com/terms_condition/Terms_and_conditions_01_06_2023.pdf"
    target="_blank"
  >
    {text}
  </LinkButton>
);

export type SelectOptionsProps = {
  title: string;
  value: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange(event: SelectChangeEvent<string>): void;
  setIsDisabled(value: boolean): void;
};

export const SelectOptions = ({
  title,
  value,
  options,
  onChange,
  setIsDisabled,
}: SelectOptionsProps) => {
  const [error, setError] = useState('');

  const onChangeHandler = (event: SelectChangeEvent<string>) => {
    setIsDisabled(!!event.target.value);
    onChange(event);
    setError('');
  };

  const onBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setIsDisabled(!event.target.value);
    if (!event.target.value) {
      setError('Field is required');
    }
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id="title-select-id">{title}</InputLabel>
      <Select
        labelId="title-select-id"
        label="Title"
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlur}
      >
        {options.map((title) => (
          <MenuItem value={title.value} key={title.label}>
            {title.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export type PhoneInputProps = {
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsDisabled(value: boolean): void;
};

export const PhoneInput = ({
  phoneNumber,
  setPhoneNumber,
  setIsDisabled,
}: PhoneInputProps) => {
  const [error, setError] = useState('');

  const isValidPhoneNumber = useCallback(() => {
    return phoneNumber.replace(/\D/g, '').length >= 10;
  }, [phoneNumber]);

  const onChange = (
    e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e === null) return;
    if (typeof e === 'string') setPhoneNumber(e);
    if (typeof e === 'object') setPhoneNumber(e.target.value);
  };

  useEffect(() => {
    if (isValidPhoneNumber()) {
      setError('');
      setIsDisabled(false);
    }
  }, [phoneNumber, isValidPhoneNumber, setIsDisabled]);

  const onBlur = () => {
    if (!isValidPhoneNumber()) {
      setError('Invalid phone number');
      setIsDisabled(true);
    }
  };

  return (
    <InputStack>
      <MuiPhoneNumber
        variant="outlined"
        defaultCountry={'us'}
        label="Phone Number"
        sx={{ width: '100%' }}
        value={phoneNumber}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </InputStack>
  );
};

export type CountryInputProps = {
  value: string;
  onChange(event: SelectChangeEvent<string>): void;
  setIsDisabled(value: boolean): void;
};

export const CountryInput = ({
  value,
  onChange,
  setIsDisabled,
}: CountryInputProps) => {
  const [error, setError] = useState('');

  const countries = useQuery(GetCountries);
  const onChangeHandler = (event: SelectChangeEvent<string>) => {
    setIsDisabled(!!event.target.value);
    onChange(event);
    setError('');
  };

  const onBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setIsDisabled(!event.target.value);
    if (!event.target.value) {
      setError('Field is required');
    }
  };
  return (
    <InputStack>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="country-select-id">Country</InputLabel>
        <Select
          labelId="country-select-id"
          label="Country"
          value={value}
          onChange={onChangeHandler}
          onBlur={onBlur}
        >
          {!countries.loading &&
            countries.data?.getCountries?.map((country, index) => (
              <MenuItem value={country?.code.trim()} key={index}>
                {country.countryname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </InputStack>
  );
};

export type NumberInputProps = {
  label: string;
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  type: 'IATA' | 'CASS';
  setIsDisabled(value: boolean): void;
};

export const NumberInput = ({
  label,
  value,
  onChange,
  type,
  setIsDisabled,
}: NumberInputProps) => {
  const [error, setError] = useState('');
  const schema = useMemo(
    () =>
      type === 'CASS'
        ? Yup.string()
            .min(4, 'Number must be of 4 digits')
            .max(4, 'Number must be of 4 digits')
            .required('Field is required')
        : Yup.string()
            .min(7, 'Number must be of 7 digits')
            .max(7, 'Number must be of 7 digits')
            .required('Field is required'),
    [type]
  );

  useEffect(() => {
    schema
      .validate(value)
      .then(() => {
        setError('');
        setIsDisabled(false);
      })
      .catch(() => {});
  }, [value, schema, setIsDisabled]);

  const onBlur = () => {
    schema
      .validate(value)
      .then(() => {
        setError('');
      })
      .catch((err: any) => {
        setError(err.message);
        setIsDisabled(true);
      });
  };

  return (
    <InputStack>
      <TextField
        type="number"
        variant="outlined"
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </InputStack>
  );
};

export type AirlinesOnBoardInputProps = {
  isOpen: boolean;
  airlines: Record<string, any>[];
  onChage(event: any, value: any): void;
  onClose(): void;
  value: any;
};

export const AirlinesOnBoardInput = ({
  isOpen,
  airlines,
  onClose,
  onChage,
  value,
}: AirlinesOnBoardInputProps) => {
  return (
    <Modal open={isOpen}>
      <ModalBackground>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Autocomplete
          sx={{ width: '100%' }}
          multiple
          filterSelectedOptions
          options={airlines || []}
          renderInput={(param) => (
            <TextField {...param} label="Airlines on board" />
          )}
          getOptionLabel={(option: any) => option?.airlineName || ''}
          onChange={onChage}
          value={value}
        />
      </ModalBackground>
    </Modal>
  );
};

export type AirlineButtonProps = {
  value: any;
  onClick(): void;
};

export const AirlinesOnBoard = ({ onClick, value }: AirlineButtonProps) => {
  return (
    <AirlineButtonStyled onClick={onClick}>
      <Typography>Airlines on board</Typography>
      <Box>{value.length}</Box>
    </AirlineButtonStyled>
  );
};

export type SixDigitInputProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SixDigitInput = ({
  value,
  setValue,
  setIsDisabled,
}: SixDigitInputProps) => {
  const [digits, setDigits] = useState(
    value.length === 6 ? value.split('') : ['', '', '', '', '', '']
  );
  const [isFocused, setFocused] = useState(false);

  const handleChange = (index, event) => {
    const { value } = event.target;
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      if (value && index < 5) {
        document.getElementById(`digit-${index + 1}`)?.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !digits[index] && index > 0) {
      document.getElementById(`digit-${index - 1}`)?.focus();
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if (digits) {
      setIsDisabled(digits.length !== 6);
      setValue(digits.join(''));
    }
  }, [digits, setValue, setIsDisabled]);

  return (
    <Stack>
      <Fragment>
        <Typography
          variant="subtitle1"
          color={isFocused ? '#051551' : 'rgba(0, 0, 0, 0.524)'}
        >
          Enter your 6 digit code
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          width={'100%'}
          margin="auto"
          gap={1}
        >
          {digits.map((digit, index) => (
            <TextField
              key={index}
              id={`digit-${index}`}
              value={digit}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
              variant="outlined"
            />
          ))}
        </Box>
      </Fragment>
    </Stack>
  );
};
