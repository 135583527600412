import moment from 'moment';
import { styled } from '@mui/material/styles';
import { GridOverlay } from '@mui/x-data-grid';
import NoDataSvg from 'src/Assets/Images/NoDataSvg';
import { Box } from '@mui/material';

export const isNull = (data) => {
  return data === undefined || data === null || data === '';
};

export const isNotNull = (data) => {
  return !isNull(data);
};

export const handleNull = (data, proxy) => {
  return isNull(data) ? proxy : data;
};

export const replaceNullObjectWithNA = (data, proxy = 'N/A') => {
  // data should be array
  if (isNotNull(data)) {
    return data.map((o) => {
      const returnObj = {};
      Object.keys(o).forEach((x) => {
        returnObj[x] = handleNull(o[x], proxy);
      });
      return returnObj;
    });
  }
};

export const replaceObjectWithNA = (data, proxy = 'N/A') => {
  //data should be object {}
  const obj = {};
  Object.keys(data).forEach((key) => {
    if (isNull(data[key])) {
      return (obj[key] = proxy);
    }
    obj[key] = data[key];
  });
  return obj;
};

export const provideUniqId = (data) => {
  // data should be array
  const newArray = data.map((element, index) => ({ ...element, id: index }));
  return newArray;
};

export const getFutureDays = (totalDays: number, format: string) => {
  let days: string[] = [];
  let daysRequired = totalDays;

  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment()?.add(i, 'days')?.format(format));
  }
  return days;
};

export const getCommodityDisplayLabel = (text) => {
  switch (text) {
    case 'GCR':
      return 'General Cargo';
    case 'DGD':
      return 'Dangerous Goods';
    case 'XPS':
      return 'Express';
    case 'PER':
      return 'Perishable';
    case 'AVI':
      return 'Live Animals';
    default:
      return '';
  }
};

export const getAirportLabel = (airport: any) => {
  if (airport?.code && airport.airportName) {
    return `${airport.code} - ${airport.airportName || ''} `;
  } else if (airport?.cityName) {
    return `${airport.cityName || ''} `;
  } else if (airport?.code) {
    return `${airport.code || ''} `;
  }
  return '';
};

export const getServiceLable = (service: any) => {
  if (service?.description) {
    return service?.description;
  }
  return '';
};

export const getFreightAgentLabel = (freightAgent: any) => {
  if (freightAgent?.companyName) {
    return freightAgent.companyName;
  }
  return '';
};
export const getAirlinesLabel = (airline: any) => {
  if (airline?.airlineName) {
    return `${airline?.airlineName}`;
  }
  return '';
};

export const getCommodityLabel = (commodity: any) => {
  if (commodity?.description) {
    return `${commodity?.description}`;
  }
  return '';
};

export const getFlightLabel = (flight) => {
  if (flight?.flight) {
    return `${flight?.flight}`;
  }
  return flight;
};

export const getRateTypesLabel = (rateTypes: any) => {
  if (rateTypes?.description) {
    return `${rateTypes?.description}`;
  }
  return '';
};

export const getAgentCodeLable = (agentCode: any) => {
  if (agentCode?.agentCode && agentCode.companyname) {
    return `${agentCode.companyname} (${agentCode.agentCode || ''}) `;
  }
  return '';
};

export const getGhaAgentLabel = (gha: any) => {
  if (gha?.title) {
    return `${gha?.title || ''}`;
  }
  return '';
};

export const getGsaAgentLabel = (gsa: any) => {
  if (gsa?.companyName) {
    return `${gsa?.companyName || ''}`;
  }
  return '';
};

export const minutes_to_hhmm = (numberOfMinutes) => {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, 'minutes');

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  //get minutes
  var mm = duration.minutes();

  //return total time in hh:mm format
  return hh + ':' + mm;
};

const GssaImage = [
  { label: 'strike aviation', path: 'images/gssa/Strike Aviation.jpg' },
  { label: 'na', path: 'images/gssa/NA.jpg' },
  {
    label: 'airbridge internatinal agency',
    path: 'images/gssa/Airbridge International Agencies.jpg',
  },
  {
    label: 'dhl global forwarding',
    path: 'images/gssa/Dhl global forwarding.png',
  },
  { label: 'transworld 2000 ltd', path: 'images/gssa/strike.jpg' },
  {
    label: 'airbridge international agencies ltd',
    path: 'images/gssa/Airbridge International Agencies Ltd.png',
  },
  { label: 'scan partners', path: 'images/gssa/scanpartners_logo.png' },
  { label: 'avia cargo', path: 'images/gssa/AVIAUS.jpg' },
  { label: 'decar ltd', path: 'images/gssa/strike.jpg' },
  {
    label: 'select airline',
    path: 'images/gssa/Select Airline Management Ltd.png',
  },
  {
    label: 'select airline management',
    path: 'images/gssa/Select Airline Management Ltd.png',
  },
  { label: 'strike', path: 'images/gssa/strike.jpg' },
  { label: 'strike germany', path: 'images/gssa/STRIKE GERMANY.jpg' },
];

export const GssaImagePath = (companyName) => {
  const capCompanyName = companyName.toLowerCase();
  let finalPath;
  let gssaPath = GssaImage.filter((gssa) => gssa.label === capCompanyName);
  if (gssaPath.length !== 0) {
    finalPath = gssaPath[0]?.path;
  } else {
    finalPath =
      'https://tricargo-images-bucket.s3.eu-west-1.amazonaws.com/airline_logos/airlines/NoImageFound.png';
  }
  return finalPath;
};

export const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

export const NoRowElement = () => {
  return (
    <>
      <NoDataSvg />
      <Box sx={{ mt: 1 }}>{'No Rows'}</Box>
    </>
  );
};

export const getQuotesStatusId = (status) => {
  switch (status) {
    case 'Pending':
      return 1;
    case 'Rate Given':
      return 2;
    case 'Closed':
      return 3;
    case 'Expired':
      return 4;
    default:
      return 1;
  }
};

enum containBatteries {
  ELI_UN3481 = 'Lithium Ion',
  ELM_UN3091 = 'Lithium Metal',
  UN3481_UN3081 = 'Both',
  NO_BATTERIES = ' ',
  ICE_CARBON = 'Carbon dioxide',
  RSB_EXPANDABLE = 'Expandable Polymeric Beads',
  RBI_LITHIUM = 'Lithium ion batteries: Class 9 UN3480',
  RLI_LITHIUM = 'Lithium ion batteries: Class 9 UN3481 Section 1',
  ELI_LITHIUM = 'Lithium ion batteries: Class 9 UN3481 Section 2',
  RBM_LITHIUM = 'Lithium metal batteries: Class 9 UN3090',
  RLM_LITHIUM = 'Lithium metal batteries: Class 9 UN3091 Section 1',
  ELM_LITHIUM = 'Lithium metal batteries: Class 9 UN3091 Section 2',
  MAG = 'Magnetized Material',
  RMD = 'Miscellaneous dangerous',
}

export const getContaineBatteriesLabel = (status: string) => {
  switch (status) {
    case containBatteries.NO_BATTERIES:
      return 'No Batteries';
    case containBatteries.ELI_UN3481:
      return 'ELI - UN3481 - Lithium ion batteries - Section ||';
    case containBatteries.ELM_UN3091:
      return 'ELM - UN3091 - Lithium metal batteries - Section ||';
    case containBatteries.UN3481_UN3081:
      return 'UN3481/UN3091 - combination - Section ||';
    case containBatteries.ICE_CARBON:
      return 'ICE - Carbon dioxide, solid (dry ice)';
    case containBatteries.RSB_EXPANDABLE:
      return 'RSB - Expandable Polymeric Beads / Plastics Moulding Compounds';
    case containBatteries.RBI_LITHIUM:
      return 'RBI - Lithium ion batteries: Class 9 UN3480, Sections IA and IB, PI 965';
    case containBatteries.RLI_LITHIUM:
      return 'RLI - Lithium ion batteries: Class 9 UN3481, Section I, PI 966 and 967';
    case containBatteries.ELI_LITHIUM:
      return 'ELI - Lithium ion batteries: Class 9 UN3481, Section II, PI 966 and 967';
    case containBatteries.RBM_LITHIUM:
      return 'RBM - Lithium metal batteries: Class 9 UN3090, Sections IA and IB PI 968';
    case containBatteries.RLM_LITHIUM:
      return 'RLM - Lithium metal batteries: Class 9 UN3091, Section I, PI 969 and 970';
    case containBatteries.ELM_LITHIUM:
      return 'ELM - Lithium metal batteries: Class 9 UN3091, Sections II, PI 969 and 970';
    case containBatteries.MAG:
      return 'MAG - Magnetized Material';
    case containBatteries.RMD:
      return 'RMD - Miscellaneous dangerous';
    default:
      return 'No Batteries';
  }
};

//Validate For Two Decimal points value
export const validate = function (e) {
  let value = e.target.value;
  if (e.target.value === '') {
    value = '0';
  }
  const t = value;
  return (e.target.value =
    t.indexOf('.') >= 0
      ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)
      : t);
};

export const decimalCount = (num) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
};

export const gcd_two_numbers = (n1, n2) => {
  n1 = Number(n1);
  n2 = Number(n2);

  let gcd = 1;
  for (let i = 1; i <= n1 && i <= n2; ++i) {
    // Checks if i is factor of both integers
    if (n1 % i === 0 && n2 % i === 0) gcd = i;
  }
  return gcd;
};

export const errorModalMessage =
  'Your excel file requires some manual adjustments. We will perform this within 24 hours and update your ‘Rates’ screen accordingly. We will let you know when this is complete. Thanks.';

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
