import gql from 'graphql-tag';

export const GetRates = gql`
  query GetRatesQuery(
    $userid: Int!
    $searchText: String!
    $origin: String!
    $destination: String!
    $weight: Float!
  ) {
    getRates(
      userid: $userid
      searchText: $searchText
      origin: $origin
      destination: $destination
      weight: $weight
    ) {
      id
      route
      gssa
      airline
      product
      weight
      ratePerKg
      currency
      freightRate
      freightAmount
      fscRate
      FSC
      sccRate
      SCC
      totalAmount
      airlineId
      msg
      flightDetails {
        origin
        destination
        flight
        flightdate
        stdDepartureTime
        stdArrivalTime
        carrierType
        isReturned
      }
      url
      ratetype
      spotRef
      powerdBy
      HFC
      WRC
      viaAirport
      soldMax
      additionalFlightDetails
    }
  }
`;

export const GetMergedRates = gql`
  query GetMergedRatesQuery(
    $origin: String!
    $destination: String!
    $weight: Float!
    $airline: String!
    $gssa: String!
  ) {
    getMergedRates(
      origin: $origin
      destination: $destination
      weight: $weight
      airline: $airline
      gssa: $gssa
    ) {
      airline
      count
      currency
      freightAmount
      airlineId
      flightDetails {
        destination
        flightdate
        flight
        origin
        stdArrivalTime
        stdDepartureTime
      }
      gssa
      id
      mergedData
      product
      ratePerKg
      route
      weight
      allFlight
    }
  }
`;

export const GetAirports = gql`
  query GetAirportsQuery(
    $searchText: String!
    $userid: Int!
    $origin: Boolean!
  ) {
    getAirports(searchText: $searchText, userid: $userid, origin: $origin) {
      code
      cityCode
      countryCode
      cityName
      airportName
    }
  }
`;

export const GetUserSearchHistory = gql`
  query GetUserSearchHistoryQuery($userid: String!) {
    getUserSearchHistory(userid: $userid) {
      id
      userid
      template
      searchText
      dateCreated
    }
  }
`;

export const GetUserTemplateHistory = gql`
  query GetUserTemplateHistoryQuery($userid: String!, $type: String!) {
    getUserSearchHistory(userid: $userid, type: $type) {
      id
      userid
      template
      searchText
      dateCreated
    }
  }
`;

export const GetDangerousGoodsList = gql`
  query GetDangerousGoodsListQuery($searchText: String!) {
    getDangerousGoodsList(searchText: $searchText) {
      id
      name
      unno
      cls
      packinggroup
      packinginstruction
      subsidiaryrisk
      shc
      shc2
    }
  }
`;

export const GetAirLine = gql`
  query GetAirLineQuery {
    getAirlines {
      airlineId
      airlineName
      awbPrefix
      carriercode
      country
    }
  }
`;

export const GetAirlinesSearch = gql`
  query GetAirlinesSearchQuery($searchText: String!, $userid: Int!) {
    getAirlinesSearch(searchText: $searchText, userid: $userid) {
      airlineId
      airlineName
      awbPrefix
      carriercode
      country
    }
  }
`;

export const GetLocationsSearch = gql`
  query GetLocationsSearchQuery($airline: String!, $userid: Int!) {
    getAirlinesSearch(airline: $airline, userid: $userid) {
      locations
    }
  }
`;

export const GetCommodityTypes = gql`
  query GetCommodityTypesQuery($searchText: String!) {
    getGoodsType(searchText: $searchText) {
      goodstypeid
      description
      specialhandlingcode
    }
  }
`;

export const GetRatesTypes = gql`
  query GetRatesTypesQuery($searchText: String!) {
    getRateType(searchText: $searchText) {
      description
      ratetypeid
    }
  }
`;

export const GetService = gql`
  query GetServiceQuery($searchText: String!) {
    getServiceDetails(searchText: $searchText) {
      description
      servicetypeid
    }
  }
`;

export const GetFreightAgent = gql`
  query GetFreightAgentQuery($searchText: String!) {
    getFreightAgent(searchText: $searchText) {
      companyName
    }
  }
`;

export const GetQuotesTarrifList = gql`
  query GetQuotesTarrifListQuery(
    $airlineId: Int!
    $commodity: String!
    $destination: String!
    $origin: String!
    $userId: Int!
    $description: String!
    $airlineName: String!
    $limit: Int!
    $offset: Int!
  ) {
    getQuotationList(
      airlineId: $airlineId
      commodity: $commodity
      destination: $destination
      origin: $origin
      userId: $userId
      description: $description
      airlineName: $airlineName
      limit: $limit
      offset: $offset
    ) {
      tariffid
      origin
      destination
      commodity
      airlineId
      ratetype
      totalCount
    }
  }
`;

export const GetRatesTarrifList = gql`
  query GetRatesTarrifListQuery(
    $airlineId: Int!
    $commodity: String!
    $destination: String!
    $viaAirport: String!
    $origin: String!
    $userId: Int!
    $ratetype: String!
    $description: String!
    $airlineName: String!
    $quicksearch: String!
  ) {
    getQuotationList(
      airlineId: $airlineId
      commodity: $commodity
      destination: $destination
      viaAirport: $viaAirport
      origin: $origin
      userId: $userId
      ratetype: $ratetype
      description: $description
      airlineName: $airlineName
      quicksearch: $quicksearch
    ) {
      tariffid
      airlineId
      airlineName
      origin
      viaAirport
      destination
      commodity
      currency
      minimum
      w500
      w45
      w3000
      w300
      w1000
      w100
      ratetype
      fuelSurcharge
      securitySurcharge
      rateperunit
      companyname
      accountId
      totalCount
      validToDate
      validFromDate
      agentCode
      agentName
      spotNo
      spotMax
      contractId
    }
  }
`;

export const GetUserBookingHistory = gql`
  query GetUserBookingHistoryQuery($userid: String!) {
    getUserBookingHistory(userid: $userid) {
      booking
      dateCreated
      id
      searchText
    }
  }
`;

export const GetAccount = gql`
  query GetAccountQuery($userid: Int!) {
    getAccount(userid: $userid) {
      dashboardUrl
    }
  }
`;

export const GetFilterSearchData = gql`
  query GetFilterSearchDataQuery(
    $airline: String!
    $commodity: String!
    $destination: String!
    $origin: String!
    $userid: Int!
    $time: String!
  ) {
    getFilterSearchData(
      airline: $airline
      commodity: $commodity
      destination: $destination
      origin: $origin
      userid: $userid
      time: $time
    ) {
      booking
      dateCreated
      searchText
      id
      frightagent
      rate
      quotesStatus
      totalCount
      forwarderCompany
      agentCode
      totalCount
      declinedReason
      locations
      pdfName
      pdfNameLink
    }
  }
`;

export const GetGssaCountryList = gql`
  query GetGssaCountryListQuery {
    getGssaCountryList {
      data
    }
  }
`;

export const GetAccountDetails = gql`
  query GetAccountDetailsQuery($userid: Int!) {
    getAccountDetails(userid: $userid) {
      firstname
      surname
      phonenumber
      companyname
      usertype
      cassNo
      iataNo
      country
      accountId
      airlines
      airlineList
      dashboardUrl
      countrycode
      userid
      companytype
      emailid
      dateCreated
      lastUpdated
      active
      status
      fiataNo
      accountStatus
      globalEmailid
      userRoles
      userlevel
      msg
      url
      street
      city
      postalCode
      country
      companyCountry
    }
  }
`;

export const GetGssaUserDetails = gql`
  query GetGssaUserDetailsQuery($userid: Int!) {
    getGssaUserDetails(userid: $userid) {
      userid
      firstName
      surName
      emailid
      companytype
      companyname
      token
      title
      iataNo
      accountId
      countrycode
      countryName
      volumeUnit
      weightUnit
      dimensionUnit
      usertype
      globalEmailid
      cassNo
      userlevel
      companyWebsite
      phoneNumber
      password
      activeStatus
      userRoles
    }
  }
`;

export const GetUserRoles = gql`
  query GetUserRolesQuery {
    getUserRoles {
      id
      description
    }
  }
`;

export const GetAllAccounts = gql`
  query GetAllAccountsQuery(
    $companyname: String!
    $companytype: String!
    $emailid: String!
    $iataNo: String!
    $accountId: Int!
    $status: String!
    $countrycode: String!
    $ratesTurned: String!
    $limit: Int!
    $offset: Int!
  ) {
    getAllAccounts(
      companyname: $companyname
      companytype: $companytype
      emailid: $emailid
      iataNo: $iataNo
      accountId: $accountId
      status: $status
      countrycode: $countrycode
      ratesTurned: $ratesTurned
      limit: $limit
      offset: $offset
    ) {
      airlineList
      accountId
      airlines
      companyname
      cassNo
      companytype
      country
      dashboardUrl
      countrycode
      emailid
      dateCreated
      firstname
      iataNo
      lastUpdated
      phonenumber
      surname
      userid
      usertype
      active
      status
      airlineList
      fiataNo
      accountStatus
      globalEmailid
      totalCount
      title
      password
      ratesTurned
    }
  }
`;

export const GetBookingSearch = gql`
  query GetBookingSearchQuery(
    $airline: String!
    $commodity: String!
    $destination: String!
    $origin: String!
    $userid: Int!
    $flight: String!
    $flightdate: String!
    $frightagent: String!
    $awb: String!
    $status: String!
    $invoiceNo: Int!
  ) {
    getBookingSearch(
      airline: $airline
      commodity: $commodity
      destination: $destination
      origin: $origin
      userid: $userid
      flight: $flight
      flightdate: $flightdate
      awb: $awb
      status: $status
      invoiceNo: $invoiceNo
      frightagent: $frightagent
    ) {
      accountId
      awbno
      booking
      dateCreated
      frightagent
      id
      rate
      searchText
      userid
      actionStatus
      status
      totalCount
      forwarderCompany
      invoiceData
      pdfName
      pdfNameLink
    }
  }
`;

export const GetBookingSearchAdmin = gql`
  query GetBookingSearchQuery(
    $airline: String!
    $commodity: String!
    $destination: String!
    $origin: String!
    $flight: String!
    $flightdate: String!
    $enddate: String!
    $companyname: String!
    $status: String!
    $awb: String!
    $invoiceNo: Int! # $limit: Int! # $offset: Int!
  ) {
    getBookingSearchAdmin(
      airline: $airline
      commodity: $commodity
      destination: $destination
      origin: $origin
      flight: $flight
      flightdate: $flightdate
      enddate: $enddate
      awb: $awb
      invoiceNo: $invoiceNo
      companyname: $companyname
      status: $status # limit: $limit # offset: $offset
    ) {
      accountId
      awbno
      booking
      dateCreated
      frightagent
      id
      rate
      status
      searchText
      userid
      actionStatus
      totalCount
      forwarderCompany
      gssaCompany
      invoiceData
      quantity
      pdfName
      pdfNameLink
    }
  }
`;

export const GetFlightsDetails = gql`
  query GetFlightsDetailsQuery(
    $airlineId: Int!
    $dateRange: String!
    $destination: String!
    $origin: String!
    $userid: Int!
    $flight: String!
    $limit: Int!
    $offset: Int!
  ) {
    getFlightsDetails(
      airlineId: $airlineId
      dateRange: $dateRange
      destination: $destination
      origin: $origin
      userid: $userid
      flight: $flight
      limit: $limit
      offset: $offset
    ) {
      aircraftType
      airlineName
      carrierCode
      flightdate
      destination
      flight
      origin
      stdarrivaltime
      stddeparturetime
      volcapacity
      wtcapacity
      airlineId
      totalCount
      flightdetailId
    }
  }
`;

export const GetAwbstock = gql`
  query GetAwbstockQuery($userid: Int!, $airlineid: String!, $status: String!) {
    getAwbstock(userid: $userid, airlineid: $airlineid, status: $status) {
      accountId
      airlineId
      awbActionStatus
      awbStatus
      awbno
      awbstockid
      bookingId
      carrierCode
      dateCreated
      dateUsed
      lastUpdated
      version
    }
  }
`;

export const GetCompanyName = gql`
  query GetCompanyNameSearchQuery($searchText: String!) {
    getCompany(searchText: $searchText) {
      companyname
    }
  }
`;

export const GetEmailId = gql`
  query GetEmailidSearchQuery($searchText: String!) {
    getEmail(searchText: $searchText) {
      emailid
    }
  }
`;
export const GetIataNo = gql`
  query GetIataNoSearchQuery($searchText: String!) {
    getIatano(searchText: $searchText) {
      iataNo
    }
  }
`;

export const GetCountryCode = gql`
  query GetCountryCodeSearchQuery($searchText: String!) {
    getCountrycode(searchText: $searchText) {
      countrycode
    }
  }
`;

export const GetCountries = gql`
  query GetCountriesQuery {
    getCountries {
      code
      countryname
    }
  }
`;

export const GetGssaUserData = gql`
  query GetGssaDetailsQuery {
    gssaDetails {
      userid
      firstName
      surName
      title
      activeStatus
    }
  }
`;

export const GetFlightList = gql`
  query GetFlightListQuery($searchText: String!) {
    getFlightList(searchText: $searchText) {
      accountId
      aircraftType
      destination
      flight
      flightdate
      flightdetailId
      isactive
      origin
      stdArrivalTime
      stdDepartureTime
      volcapacity
      wtcapacity
    }
  }
`;

export const GetValidFlightCode = gql`
  query GetValidFlightCodeQuery($searchText: String!) {
    getValidFlightCode(searchText: $searchText)
  }
`;

export const GetReasons = gql`
  query GetReasonsQuery($reasonsCategory: String!) {
    getReasons(reasonsCategory: $reasonsCategory) {
      id
      reasons
      reasonsCategory
    }
  }
`;

export const GetBookingStatusCount = gql`
  query GetBookingStatusCountQuery($userId: String!) {
    getBookingStatusCount(userId: $userId) {
      archivedQuotes
      bookingRequested
      readyToBook
      pendingQuotes
      confirmBooking
      unreadQuotes
      unreadShipments
      totalQuotes
      totalShipments
    }
  }
`;

export const GetUldTypes = gql`
  query GetUldTypesQuery($userId: String!) {
    getUldTypes(userId: $userId) {
      containerType
      description
      grossWeightUnit
      internalVolume
      id
      maxGrossWeight
      uldCode
      volumeUnit
    }
  }
`;
export const GetSSIMFlightData = gql`
  query getSsimFlightDetailsQuery(
    $ssimHeaderId: Int!
    $flight: String!
    $origin: String!
    $destination: String!
    $airlineDesignator: String!
    $periodOperationFrom: String!
    $periodOperationTo: String!
    $limit: Int!
    $offset: Int!
  ) {
    getSsimFlightDetails(
      ssimHeaderId: $ssimHeaderId
      flight: $flight
      origin: $origin
      destination: $destination
      airlineDesignator: $airlineDesignator
      periodOperationFrom: $periodOperationFrom
      periodOperationTo: $periodOperationTo
      limit: $limit
      offset: $offset
    ) {
      airlineName
      aircraftType
      arrivalStation
      dateCreated
      departureStation
      stdDepartureTime
      stdArrivalTime
      wtcapacity
      legSequenceNumber
      lastUpdated
      flightdetailId
      flightdate
      flightNumber
      flight
      totalCount
    }
  }
`;

export const GetSSIMHeaderData = gql`
  query getSsimhdrDetailQuery($limit: Int!, $offset: Int!) {
    getSsimhdrDetail(limit: $limit, offset: $offset) {
      airlineDesignator
      dateCreated
      lastUpdated
      periodOfScheduleValidityFrom
      periodOfScheduleValidityTo
      recordSerialNumber
      scheduleStatus
      season
      ssimhdrId
      timeMode
      airlineName
      totalCount
    }
  }
`;
export const GetSSIMData = gql`
  query GetSSIMDataQuery($ssimHeaderID: Int!, $limit: Int!, $offset: Int!) {
    getSsimDetail(ssimHeaderID: $ssimHeaderID, limit: $limit, offset: $offset) {
      ssimhdrId
      ssimId
      airlineDesignator
      flightNumber
      legSequenceNumber
      periodOfOperationFrom
      periodOfOperationTo
      daysOfOperation
      departureStation
      scheduledTimeOfAircraftDeparture
      utcLocalTimeVariationDeparture
      serviceType
      arrivalStation
      scheduledTimeOfAircraftArrival
      utcLocalTimeVariationArrival
      aircraftType
      recordSerialNumber
      lastUpdated
      dateCreated
      totalCount
    }
  }
`;

export const GetAdminUserDetails = gql`
  query GetAdminUserDetailsQuery(
    $companyType: String!
    $status: String!
    $countrycode: String!
    $limit: Int!
    $offset: Int!
    $userType: String!
  ) {
    getAdminUserDetails(
      companyType: $companyType
      status: $status
      countrycode: $countrycode
      limit: $limit
      offset: $offset
      userType: $userType
    ) {
      userid
      firstName
      surName
      emailid
      companytype
      companyname
      token
      title
      iataNo
      accountId
      countrycode
      countryName
      volumeUnit
      weightUnit
      dimensionUnit
      usertype
      cassNo
      userlevel
      companyWebsite
      phoneNumber
      password
      activeStatus
      userRoles
      totalCount
    }
  }
`;

export const GetFedagsaMembersDetails = gql`
  query GetFedagsaMembersDetailsQuery(
    $limit: Int!
    $offset: Int!
    $countryCode: String!
  ) {
    getFedagsaMemberDetails(
      countryCode: $countryCode
      limit: $limit
      offset: $offset
    ) {
      country
      gssaId
      countryCode
      companyname
      status
      totalCount
    }
  }
`;

export const GetCities = gql`
  query GetCitysQuery {
    getCitys {
      citycode
      cityname
    }
  }
`;

export const GetAgentCode = gql`
  query GetAgentCodeQuery($countrycode: String!, $searchText: String!) {
    getAgentCode(countrycode: $countrycode, searchText: $searchText) {
      companyname
      agentCode
      countrycode
    }
  }
`;

export const GetBillListing = gql`
  query GetBillListingQuery(
    $accountId: Int!
    $date: String!
    $invoiceNo: String!
  ) {
    getBillListing(accountId: $accountId, date: $date, invoiceNo: $invoiceNo) {
      invoiceId
      invoiceNo
      accountId
      invoiceDate
      currency
      exchangeRate
      amount
      quantity
      vatAmount
      totalDue
      remarks
      totalCount
      domain
      statusCode
      msg
      url
      street
      city
      postalCode
      country
      companyname
    }
  }
`;

export const GetGhaAgent = gql`
  query GetGhaAgentQuery($title: String!) {
    getGhaAgent(title: $title) {
      code
      country
      ghaId
      title
      regionId
    }
  }
`;

export const GetDeliveryPoint = gql`
  query GetDeliveryPointQuery(
    $accountId: Int!
    $airlines: String!
    $airport: String!
    $dlvCode: String!
    $gha: String!
  ) {
    getDeliveryPoint(
      accountId: $accountId
      airlines: $airlines
      airport: $airport
      dlvCode: $dlvCode
      gha: $gha
    ) {
      deliverypointId
      airport
      gha
      dlvpointCode
      location
      airlines
      accountId
      msg
    }
  }
`;

export const GetDelcode = gql`
  query GetDelcodeQuery($carriercode: String!, $origin: String!) {
    getDelcode(carriercode: $carriercode, origin: $origin) {
      dlvpointCode
      location
      gha
    }
  }
`;

export const GetRegion = gql`
  query GetRegionQuery {
    getRegion {
      region
      regionId
    }
  }
`;

export const GetGhaList = gql`
  query GetGhaListQuery(
    $country: String!
    $region: String!
    $title: String!
    $limit: Int!
    $offset: Int!
  ) {
    getGha(
      country: $country
      region: $region
      title: $title
      limit: $limit
      offset: $offset
    ) {
      ghaId
      title
      country
      code
      regionName
      totalCount
    }
  }
`;

export const GetSurchargesList = gql`
  query GetSurchargesListQuery(
    $accountId: Int!
    $airlineName: String!
    $chargesType: String!
  ) {
    getSurcharges(
      accountId: $accountId
      airlineName: $airlineName
      chargesType: $chargesType
    ) {
      surchargeId
      origin
      destination
      soldRate
      soldMin
      soldMax
      airlineName
      accountId
      dateCreated
      lastUpdated
      chargesType
      chargeRule
      flight
      startDate
      endDate
      chargeRuleId
    }
  }
`;

export const GetChargesType = gql`
  query GetChargesTypeQuery($description: String!) {
    getCharges(description: $description) {
      chargesId
      chargeCode
      description
      chargeRuleId
    }
  }
`;

export const GetChargeRulesType = gql`
  query GetChargeRulesTypeQuery($description: String!) {
    getChargesRules(description: $description) {
      mChargeRulesId
      rule
      description
      ruleType
    }
  }
`;

export const GetCountriesList = gql`
  query GetCountriesList($searchText: String!) {
    getCountriesList(searchText: $searchText)
  }
`;

export const GetToolTip = gql`
  query GetToolTip($userId: Int!) {
    getToolTip(userId: $userId)
  }
`;

export const GetToolTipForwarder = gql`
  query GetToolTipForwarder($userId: Int!) {
    getToolTipForwarder(userId: $userId)
  }
`;

export const GetMarkAsRead = gql`
  query GetMarkAsRead($itemId: Int!, $messageType: String!) {
    getMarkAsRead(itemId: $itemId, messageType: $messageType)
  }
`;

export const GetMarkAsReadForwarder = gql`
  query getMarkAsReadForwarder($itemId: Int!, $messageType: String!) {
    getMarkAsReadForwarder(itemId: $itemId, messageType: $messageType)
  }
`;

export const GetCountriesAssociations = gql`
  query GetCountriesAssociations {
    getCountriesAssociations {
      countryAssoc
      countryAssociated
    }
  }
`;

export const GetCountryCodes = gql`
  query GetCountryCodes($country: String!) {
    getCountryCodes(country: $country) {
      code
      countryname
    }
  }
`;

export const GetDeliveryPointLocations = gql`
  query GetDeliveryPointLocations(
    $airlineCode: String!
    $accountId: Int!
    $origin: String!
  ) {
    getDeliveryPointLocations(
      airlineCode: $airlineCode
      accountId: $accountId
      origin: $origin
    ) {
      deliverypointId
      ghaId
      airport
      dlvpointCode
      location
      airlines
      accountId
      ghaTitle
    }
  }
`;

export const GetSurchargesRateForUser = gql`
  query GetSurchargesRateForUser(
    $accountId: Int!
    $airlineName: String!
    $origin: String!
    $destination: String!
  ) {
    getSurchargesRateForUser(
      accountId: $accountId
      airlineName: $airlineName
      origin: $origin
      destination: $destination
    ) {
      soldRate
      chargeRule
      soldMin
      soldMax
      chargesType
      origin
      destination
    }
  }
`;

export const GetXlsStatus = gql`
  query GetXlsStatus($sessionId: String!) {
    getXlsStatus(sessionId: $sessionId)
  }
`;

export const GetSearchesResults = gql`
  query GetSearchesResults {
    getSearchesResults {
      id
      date
      forwarderName
      origin
      destination
      chargeableWeight
      gsaListed
      gsaResponded
      quoteNumber
      status
    }
  }
`;

export const GetXlsExport = gql`
  query GetXlsExport($filter: String!) {
    getAwbStockAdmin(filter: $filter) {
      awbNo
      awbStatus
      bookingId
      dateUsed
      dateCreated
      lastUpdated
      carriercode
      id
      origin
      destination
      freightCharge
      gsa
    }
  }
`;

export const GetTermsPdfs = gql`
  query GetTermsPdfs($accountId: Int!, $airlineCode: String!) {
    getTermsPdfs(accountId: $accountId, airlineCode: $airlineCode) {
      id
      link
      name
    }
  }
`;

export const GetGsaAwbs = gql`
  query GetGsaAwbs($accountId: Int!) {
    getGsaAwbs(accountId: $accountId) {
      id
      awbno
      status
    }
  }
`;

export const GetGsaAwbFilters = gql`
  query GetGsaAwbFilters {
    getAirlines {
      airlineId
      airlineName
      carriercode
      awbPrefix
      citycode
      cityname
      country
      totalCount
    }
    getGsaList {
      userid
      emailid
      companyname
      accountId
    }
  }
`;

export const GetFedagsaGsa = gql`
  query GetFedagsaGsaQuery(
    $companyName: String!
    $countryCode: String!
    $continent: String!
  ) {
    getFedagsaGsa(
      companyName: $companyName
      countryCode: $countryCode
      continent: $continent
    ) {
      gssaId
      companyName
      country
      countryCode
      continent
    }
  }
`;
