export const PdfViewer = () => {
  const url = localStorage.getItem('pdf') || '';
  return (
    <div style={{ height: '100dvh' }}>
      <iframe
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="PDF Viewer"
      />
    </div>
  );
};

export default PdfViewer;
