import { Box, Button, Stack } from '@mui/material';
import styled from 'styled-components';

export const ModalAnchor = styled(Box)`
  position: absolute;
  inset: 0;
  margin: auto;
  width: clamp(300px, 70vw, 500px);
  height: fit-content;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 60px;
  border: 2px solid #262529;
  gap: 20px;
`;

export const CloseButton = styled(Button)`
  position: absolute !important;
  top: 10px;
  right: 10px;
`;

export const HeaderStack = styled(Stack)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
`;
