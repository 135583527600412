import { Fragment, useEffect, useState } from 'react';
import { AuthBaseModal } from '../AuthBaseModal';
import {
  PasswordInput,
  SixDigitInput,
  SubmitButton,
  TextInput,
} from '../components/auth';
import { Button, Stack, Typography } from '@mui/material';
import { BorderRadiusButton } from '../SignIn/styled';
import { useMutation } from '@apollo/client';
import {
  EmailVerification,
  ForgetUserPassword,
  OtpVerification,
} from 'src/Reducers/mutations';
import { useErrorHandler } from 'src/hooks';
import { interval, map, takeUntil } from 'rxjs';

export type ForgetPasswordProps = {
  isOpen: boolean;
  onClose: () => void;
  openSignUpModal: () => void;
};

export const ForgetPassword = ({
  isOpen,
  onClose,
  openSignUpModal,
}: ForgetPasswordProps) => {
  const [email, setEmail] = useState<string>('nazar@codemyday.com');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isOtpSent, setIsOTPSent] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [otpCode, setOtpCode] = useState<string>('');
  const [isOtpValid, setIsOtpValid] = useState<boolean>(false);
  const [canSendOtp, setCanSendOtp] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const textHandler =
    (state: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      state(event.target.value);

  const onSignUp = () => {
    openSignUpModal();
    onClose();
  };
  const { setMsgConfig } = useErrorHandler();
  const [verifyEmail] = useMutation<any>(EmailVerification);
  const [verifyOtp] = useMutation<any>(OtpVerification);
  const [resetPassword] = useMutation<any>(ForgetUserPassword);
  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    if (isOtpSent || canSendOtp) {
      const countdown$ = interval(1000).pipe(
        map((value) => 300 - value - 1),
        takeUntil(interval(300000))
      );

      const subscription = countdown$.subscribe({
        next: (secondsLeft) => setTimeLeft(secondsLeft),
        complete: () => {
          setCanSendOtp(true);
          setTimeLeft(300);
        },
      });
      return () => subscription.unsubscribe();
    }
  }, [isOtpSent, canSendOtp]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  const resendOtp = async () => {
    await verifyEmailHandler();
    setCanSendOtp(false);
  };

  const verifyEmailHandler = async () => {
    setIsSubmitting(true);
    try {
      const response = await verifyEmail({
        variables: {
          email,
        },
      });
      const parsedMessage = JSON.parse(response?.data?.emailVerification);
      if (parsedMessage[0].status === 'success') {
        setMsgConfig({
          alertOpen: true,
          message: 'Please check your email for OTP!',
          severity: 'info',
        });
        setIsOTPSent(true);
        setIsDisabled(true);
      } else {
        setMsgConfig({
          alertOpen: true,
          message: parsedMessage[0].message,
          severity: 'error',
        });
      }
    } catch (error) {
      setMsgConfig({
        alertOpen: true,
        message: error,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const verifyOtpHandler = async () => {
    setIsSubmitting(true);
    try {
      const response = await verifyOtp({
        variables: {
          email,
          otp: otpCode,
        },
      });
      const parsedMessage = JSON.parse(response?.data?.otpVerification);
      if (parsedMessage[0].status === 'success') {
        setMsgConfig({
          alertOpen: true,
          message: 'Please reset your password!',
          severity: 'info',
        });
        setIsOtpValid(true);
        setIsDisabled(true);
      } else {
        setMsgConfig({
          alertOpen: true,
          message: parsedMessage[0].message,
          severity: 'error',
        });
      }
    } catch (error) {
      setMsgConfig({
        alertOpen: true,
        message: error,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(
    () => setIsDisabled(!(password === confirmPassword)),
    [password, confirmPassword]
  );

  const resetPasswordHandler = async () => {
    setIsSubmitting(true);
    try {
      const response = await resetPassword({
        variables: {
          email,
          otp: otpCode,
          password,
        },
      });
      const parsedMessage = JSON.parse(response?.data?.forgetUserPassword);
      if (parsedMessage[0].status === 'success') {
        setMsgConfig({
          alertOpen: true,
          message: 'Password reset successfully!',
          severity: 'info',
        });
        onClose();
      } else {
        setMsgConfig({
          alertOpen: true,
          message: parsedMessage[0].message,
          severity: 'error',
        });
      }
    } catch (error) {
      console.log(error);
      setMsgConfig({
        alertOpen: true,
        message: error,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthBaseModal title="Forget Password" isOpen={isOpen} onClose={onClose}>
      <Fragment>
        {isOtpValid ? (
          <Fragment>
            <Typography>Reset your password</Typography>
            <PasswordInput
              label="New Password"
              value={password}
              onChange={textHandler(setPassword)}
              setIsDisabled={() => {}}
            />
            <PasswordInput
              label="Confirm Password"
              value={confirmPassword}
              onChange={textHandler(setConfirmPassword)}
              setIsDisabled={() => {}}
            />
            <SubmitButton
              isDisabled={isDisabled}
              text="Submit"
              onClick={resetPasswordHandler}
              isSubmitting={isSubmitting}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Typography>Enter your email address to start</Typography>
            <TextInput
              label="Email"
              value={email}
              onChange={textHandler(setEmail)}
              validation="email"
              setIsDisabled={setIsDisabled}
            />
            {!isOtpSent && (
              <SubmitButton
                isDisabled={isDisabled}
                text="Verify"
                onClick={verifyEmailHandler}
                isSubmitting={isSubmitting}
              />
            )}
            {isOtpSent && !isOtpValid ? (
              <Fragment>
                <Stack>
                  <SixDigitInput
                    value={otpCode}
                    setValue={setOtpCode}
                    setIsDisabled={setIsDisabled}
                  />
                  {!canSendOtp && (
                    <Typography>
                      Didn't get OTP? Resend in {formatTime(timeLeft)}
                    </Typography>
                  )}
                  {canSendOtp && (
                    <Typography>
                      Didn't get OTP?
                      <Button onClick={resendOtp} sx={{ padding: '0' }}>
                        Resend
                      </Button>
                    </Typography>
                  )}
                </Stack>
                <SubmitButton
                  isDisabled={isDisabled}
                  text="Submit"
                  onClick={verifyOtpHandler}
                  isSubmitting={isSubmitting}
                />
              </Fragment>
            ) : null}
            <Typography textAlign="center">
              Don't have an account?
              <BorderRadiusButton onClick={onSignUp}>
                Sign Up
              </BorderRadiusButton>
            </Typography>
          </Fragment>
        )}
      </Fragment>
    </AuthBaseModal>
  );
};
