import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';

export const Content = styled.div`
  .quotesWrapper {
    display: flex;
    background: #EBEDF4;
    padding: 150px 2% 40px 2%;
    font-size: 16px;
    @media (max-width: 1399px) {
      display: block;
    }
    @media (max-width: 1440px) {
      padding: 130px 20px 20px 20px;
    }
    @media (max-width: 768px) {
      padding: 130px 20px 40px 20px;
    }
    .searchSummary-three{
      font-weight: bold !important;
      font-size:14px !important;
    }
    .pendingDetailSect {
      @media (max-width: 1399px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      .BoxPerent{
        .allQuotes,.readyToBook,.bookingRequested,.archived{
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          box-sizing: border-box;
          border-radius: 5px;
          }
        .card-description{
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 8px;
        }
        .count-text{
          color: #2D347A;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 10px !important;
        }
      }
    }
    .BoxPerent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    }
    .MuiPaper-elevation1{
      display: flex;
    align-items: center;
    justify-content: space-between;
    }
    .MuiCardContent-root{
      padding:10px;
      font-size: 24px;
    }
    .options{
      display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .origin{
      margin-right: 10px;
      .origin-heading ,.destination-heading ,.airline-heading ,.commodity-heading{
        color: #3E47A2;
        font-size: 12px;
        font-weight: 400;
      }
    }
    }
    .quotesAndBookingStatus{
      .airline-image {
        border-radius: 50%;
        height: 48px;
        width: 48px;
        object-fit: contain;
        background-position: 50% 50%;
      }
    }
    .origin{
      width: 100%;
      display: flex;
    align-items: center;
    }
    .origin p{
      padding-right: 10px;
    }
    .css-1knii75-MuiStack-root{
      width: 150px;
    }
    .MuiAutocomplete-root{
      width: 100%;
    }
    .MuiInput-root{
      height: 55px;
    background: transparent;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 0 9px;
    }
    .flightdate-picker{
      font-size: 16px !important;
      height: 43px !important;
      width: 100% !important;
      border: 1px solid #bdbdbd;
      padding-left: 8px;
      border-radius: 3px;
    }
   
    .MuiDataGrid-columnHeadersInner{
      transform: translate3d(32px, 0px, 0px);
    }
    .MuiDataGrid-columnHeaderTitleContainer{
      justify-content: flex-start;
      padding: 0;
    }

    .quotesDetail {
      .quotesDetailWrapper {
        .chartWrapper {
          display: flex;
          align-items: center;
          .chartMain {
            width: 36px;
            height: 36px;
            background: #051551;
            box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 959px) {
              margin-left: 15px;
            }
            @media (max-width: 599px) {
              margin-left: 0px;
            }
          }
        }
      }
    }
        .bookingDetail {
          .bookingMainWrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 28px;
            @media (max-width: 599px) {
              display: block;
            }
            @media (max-width: 959px) {
              flex-flow: wrap;
            }
            .pendingQuotes {
              border: 1px solid #e0e0e0;
              box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
              background: #ffffff;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 10px;
              margin: 0px 15px;
              @media (max-width: 959px) {
                margin: 5px 0px;
              }
              .pendingMain {
                display: flex;
                align-items: center;
                .top-heading{
                  font-family: 'Poppins';
                font-weight: 500;
                font-size: 10px !important;
                }
                .pendingSymbol {
                  background: #ed1c24;
                  border-radius: 3px;
                  width: 15px;
                  height: 15px;
                  margin-right: 12px;
                }
                .bookingSymbol {
                  background: #f99e1c;
                }
                .confirmSymbol {
                  background: #079626;
                }
                h2 {
                  font-size: 12px;
                  font-weight: 600;
                }
              }
              h1 {
                color: #051551;
                font-size: 12px;
                font-weight: bold;
              }
              .titleGb {
                color: #828282;
              }
            }
            .pendingSpace {
              margin: 0px 15px 0px 0px;
              @media (max-width: 599px) {
                margin: 0px 0px 0px 0px;
              }
            }
            .gbMain {
              margin: 0px 0px 0px 15px;
              width: 80%;
              @media (max-width: 599px) {
                width: 100%;
              }
            }
            .chartWrapper {
              display: flex;
              align-items: center;
              .chartMain {
                width: 45px;
                height: 36px;
                background: #051551;
                box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 959px) {
                  margin-left: 15px;
                }
                @media (max-width: 599px) {
                  margin-left: 0px;
                }
              }
            }
          }
        }
        .shipmentAirport {
            width: 100%;
            min-height: 79vh;
            height: auto;
          padding: 20px 30px;
          // padding: 20px 30px 0px 30px;
          margin-bottom: 20px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          max-width: 100%;
          @media (max-width: 425px) {
            padding: 20px;
          }
          .AccountsTableHeader {
            display: flex; 
            justify-content: end;
            align-items:center;
            gap:50px;
            margin-bottom:25px;
            p{
              color:black;
              font-size:12px;
              font-weight:600;
              line-height:16px
            }
          }
          .questionCheck {
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 12px;
            & > p {
              margin-bottom: 0px;
            }
          }
          .cargoShipment {
            .airportDetail {
              .cargoSelect {
                .MuiOutlinedInput-notchedOutline {
                  border-color: rgba(0, 0, 0, 0.23);
                  border-width: 2px;
                }
              }
            }
          }
          .shipmentMain {
            justify-content: space-between;
            .MuiGrid-grid-md-4 {
              max-width: 30.333333%;
              flex-basis: 30.333333%;
              @media (max-width: 767px) {
                max-width: 80%;
                flex-basis: 80%;
              }
              @media (max-width: 575px) {
                max-width: 100%;
                flex-basis: 100%;
              }
            }
          }
          .airportDetail {
            p {
              font-size: 20px;
              font-weight: 500;
              white-space: nowrap;
              color: #333;
            }
            .textFields {
              width: 100%;
              .MuiInputBase-root {
                background-color: transparent;
                padding: 0;
                .MuiAutocomplete-endAdornment {
                  top: 2px;
                  button {
                    padding: 0;
                  }
                }
                input {
                  color: #6a717c;
                  border: 1px solid #bdbdbd;
                  color: black;
                  border-top-left-radius: 0px;
                  border-top-right-radius: 0px;
                  font-size: 16px;
                  padding-bottom: 5px !important;
                  padding: 10px;
                  min-width: 50px;
                  :before {
                    border-bottom: none;
                  }
                }
              }
              .MuiInputBase-root:before,
              .MuiInputBase-root:after {
                border-bottom: none;
              }
            }
            .MuiBox-root-16 {
              .ServiceTabList {
                @media (max-width: 768px) and (min-width: 426px) {
                  width: 100%;
                }
              }
            }
            .serviceTab {
              border: 1px solid #e0e0e0;
              padding: 10px 30px 10px;
              font-weight: 500;
              font-size: 16px;
              text-transform: capitalize;
              width: 202px;
              height: 42px;
              color: #333;
              @media (max-width: 768px) and (min-width: 426px) {
                width: 33.33%;
              }
              &:not(.Mui-selected) {
                opacity: 0.75;
              }
              @media (max-width: 475px) {
                padding: 10px 10px 10px;
              }
              &.Mui-selected {
                background: #051551;
                color: white;
              }
            }
            @media (max-width: 425px) {
              .ServiceTabList {
                display: flex;
                flex-direction: column;
                margin: auto;
              }
            }
            .serviceDetail {
              .cargo {
                color: #6a717c;
                background: #eaf2fe;
                @media (max-width: 475px) {
                  padding: 10px 10px 10px;
                }
              }
              .temp {
                border-left: 0;
                border-radius: 6px 6px 6px 6px;
              }
            }
          }
          .packageCheck {
            p {
              margin-bottom: 0;
            }
            svg {
              color: #051551;
            }
            .Mui-checked {
              color: red;
              .MuiTypography-root {
                font-weight: bold;
              }
            }
            span {
              font-size: 16px;
              width: max-content;
              :hover {
                background-color: rgba(63, 133, 243, 0.04);
              }
            }
          }
          .cargoShipment {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-flow: wrap;
            margin-bottom: 10px;
            @media (max-width: 599px) {
              display: block;
            }
            .airportDetail {
              .cargoSelect {
                width: 100%;
                .MuiSelect-select {
                  padding: 8px 20px;
                }
              }
              .MuiFormControl-root {
                min-width: 150px;
                width: 100%;
                max-width: 250px;
                .MuiInput-root {
                  &:before {
                    border-bottom: none;
                  }
                  &:after {
                    border-bottom: none;
                  }
                  .MuiNativeSelect-select {
                    border: 1px solid #bdbdbd;
                    padding: 3px 15px;
                    color: #000;
                  }
                  .MuiSvgIcon-root {
                    right: 5px;
                  }
                }
              }
            }
            .aircraftShift {
              display: flex;
              align-items: center;
              justify-content: center;
              @media (max-width: 959px) {
                justify-content: start;
                margin: 10px 0px;
              }
              @media (max-width: 425px) {
                display: block;
              }
              p {
                font-weight: normal;
                margin-bottom: 0;
                margin-right: 10px;
                @media (max-width: 1399px) {
                  margin-left: 20px;
                }
                @media (max-width: 959px) {
                  margin-left: 0px;
                }
              }
              .MuiFormControl-root {
                .MuiRadio-colorSecondary {
                  color: #000;
                }
              }
            }
          }
          .quantityDetail {
            align-items: flex-end;
            padding: 5px 0px;
            flex-wrap: nowrap;
            justify-content: space-between;
            > hr {
              @media (max-width: 1401px) {
                display: inline;
              }
            }
            .stackableMain {
              justify-content: end;
              @media (max-width: 1400px) {
                justify-content: start;
              }
            }
            .MuiGrid-item {
              display: flex;
              align-items: center;
              max-width: 67%;
              @media (max-width: 1399px) {
                max-width: 100%;
              }
              @media (max-width: 767px) {
                flex-flow: wrap;
              }
              .airportDetail {
                margin: 0px 12.5px;
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }
                .cargoSelect {
                  width: 100%;
                  .MuiOutlinedInput-input {
                    padding: 5px 34px 5px 14px !important;
                  }
                  .MuiOutlinedInput-notchedOutline {
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                  }
                }
                @media (max-width: 1440px) {
                  margin: 0px 15px;
                }
              }
              .widthWrap {
                position: relative;
                .cargoSelect {
                  .MuiInputBase-input {
                    padding: 5px 34px 5px 14px !important;
                  }
                  .MuiOutlinedInput-notchedOutline {
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                  }
                }
              }
            }
            @media (max-width: 1400px) {
              display: block;
              .MuiGrid-root {
                margin: 10px 0px;
                max-width: 100% !important;
              }
            }
            @media (max-width: 1023px) {
              .MuiGrid-root {
                .airportDetail {
                  margin: 0px 25px 20px 0px;
                }
              }
            }
            @media (max-width: 1023px) and (min-width: 768px) {
              .MuiGrid-root {
                .airportDetail {
                  width: 35%;
                }
              }
            }
            @media (max-width: 767px) {
              .MuiGrid-root {
                .airportDetail {
                  margin: 0px 10px 20px 0px;
                }
              }
            }
            @media (max-width: 425px) {
              .MuiGrid-root {
                .airportDetail {
                  margin: 0px 0px 10px 0px;
                  .MuiFormControlLabel-root {
                    margin-right: 10px;
                  }
                }
              }
            }
            @media (max-width: 530px) {
              .MuiGrid-root {
                flex-flow: wrap;
              }
            }
            @media (max-width: 425px) {
              .MuiGrid-root {
                flex-flow: wrap;
              }
            }
          }
          .addGroup {
            margin: 10px 0px 0px;
            @media (max-width: 1024px) {
              margin: 0px 0px 0px;
            }
            .addPiece {
              text-transform: none;
              font-size: 14px;
              font-weight: bold;
              padding: 0px 16px;
              color: #333333;
            }
            .addPieceIcon {
              color: #fdae15;
              padding: 0px;
            }
          }
          .secureGroup {
            display: flex;
            align-items: center;
            @media (max-width: 640px) {
              display: block;
            }
            .btnStack {
              .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                  margin-right: 5px;
                }
                flex-direction: row;
                align-items: baseline;
                @media (max-width: 1457px) and (min-width: 1441px) {
                  margin: 0px 0px 0px -3px;
                }
                span.MuiCheckbox-root {
                  :hover {
                    background-color: rgba(63, 133, 243, 0.04);
                  }
                  .MuiIconButton-label {
                    svg {
                      color: #2d347a;
                    }
                  }
                }
                .MuiTypography-root {
                  color: #000000;
                  font-size: 14px;
                }
                span.Mui-checked {
                  color: initial;
                }
              }
            }
            .btnConsol {
              margin: 0px 50px;
              @media (max-width: 640px) {
                margin: 0px 0px;
              }
            }
          }

          .datepickerField {
            .MuiFormControl-root {
              width: 100%;
              .MuiInputBase-input {
                padding: 2px;
              }

              .MuiInputBase-root {
                padding: 3px 12px 3px;
                border-radius: 0px;

                fieldset {
                  border: 1px solid #bdbdbd;
                  color: black;
                }
              }
              .MuiInputBase-root:before,
              .MuiInputBase-root:after {
                border-bottom: none;
              }
            }
          }
        }
        .shipmentDims {
          .MuiDivider-vertical {
            @media (min-width: 1280px) {
              height: 81px;
              background-color: #e0e0e0;
            }
          }
        }
        .airportRatio {
          .airportDetail {
            width: 100%;
            max-width: 180px;
            p {
              color: #000000;
              font-size: 14px;
              font-weight: 500;
            }
            h5 {
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
        .densityRatio {
          padding: 20px 30px;
          border-radius: 5px 5px 0px 0px;
          border-top: 7px solid #f99e1c;
        }
        .saveSearch {
          display: flex;
          gap: 20px;
        }
        .getQuotes {
          padding: 20px 0px 0px 0px;
          @media (max-width: 475px) {
            text-align: center;
          }
          button {
            background: #ed1c24;
            color: #fff;
            text-transform: capitalize;
            font-size: 16px;
            border-radius: 25px;
            width: 187px;
            height: 42px;
            @media (max-width: 1440px) {
              padding: 8px 24px;
            }
            @media (max-width: 425px) {
              padding: 8px 10px;
            }
          }
        }
      }
    }
  }
.commodity-box{
  @media (max-width: 1199px) {
    max-width:150px;
    width:100% !important;
  }
  @media (max-width: 991px) {
    max-width:45%;
  }
}
  .shipmentWrapper{
    width: 100%;
  }
  .action_container{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .disabled_btn{
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
      pointer-events:none;
      cursor: not-allowed;
    }
    .confirm_btn{
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
    }
    .reffer_btn{
      margin-left:10px;
      background: #FDAE15;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
    }
  }
  .BoxPerent .MuiPaper-root{
    min-width: 167px !important;
    box-shadow: 0 10px 25px #00000042 !important;
  }
  .pendingDetailSect{
    width: 100%;
  }

  .grid-wrap{
    display: flex;
  }

  .MuiFilledInput-root{
    padding-top: 3px !important;
  }
  .origin .MuiFormControl-root{
    width: 100%;
  }
  .options .origin{
    display: block !important;
  }
  .MuiButtonBase-root{
    font-size: 11px;
    height: 35px;
  }
  .Commodity .MuiInputBase-adornedEnd{
    height: 42px;
  }
  .options{
    align-items: flex-end !important;
  }
 
  .pendingQuotes{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background: white !important;
    padding: 10px 7px !important;
    width: 227px !important;
    border-radius: 5px !important;
    margin-left: 13px !important;
    box-shadow: 0 10px 25px #00000042 !important;
  }
  .pendingQuotes .titleGb{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #828282 !important;  
  }
   
.chartMain {
    width: 45px !important;
  }
  .BoxPerent .MuiCardContent-root p{
    font-size: 10px;
  }
  .MuiCardContent-root{
    font-size: 12px !important;
  }
 
  .MuiCardContent-root{
    display: flex;
    align-items: center;
  }
  .top-heading{
    font-family: Poppins;
    font-size: 10px !important;
    line-height: 15px;
    color: #051551;
    font-weight: 500 !important;
  }
  .count-box{
    .top-heading{
      font-family: 'Poppins';
      font-weight: 500 !important;
      font-size: 10px !important;
      color: #000;
    }
    margin-left: 7px;
  }
  .recent-search div[role="tabpanel"] > .MuiBox-root {padding: 0.5rem !important;}
  @media (max-width: 1360px) {
    .tab-panel .recent-search-item .details .totals-summary {text-align: left !important;}
    .recent-search .MuiButtonBase-root{max-width: 50%;}
  }
  @media (max-width: 1199px) {
    .chartWrapper{margin-top:15px;}
    .quotesWrapper .options{flex-wrap: wrap;justify-content: flex-start;}
    .quotesWrapper .options .origin {width: auto;margin-bottom:15px;}
    .tab-panel .recent-search-item .details .totals-summary {text-align: left !important;}
    .recent-search .MuiButtonBase-root{max-width: 50%;}
  }
  @media (max-width: 992px) {
   .BoxPerent .MuiPaper-root {
      min-width: inherit;
      box-shadow: 0 10px 25px #00000042 !important;
      width: 48%;
      margin-bottom: 15px;
  }
  .chartWrapper{margin-top:0px;}
  .quotesWrapper .options .origin {width: 45%;}
  }
  @media (max-width: 767px) {
    .grid-wrap {display: block;}
    .grid-wrap .recent-search {margin-right: 0px;width: 100%;height: 260px;margin-bottom:20px;}
    .grid-wrap .BoxPerent .MuiPaper-root {min-width: inherit !important;width: 100%;}
    .grid-wrap .pendingDetailSect {width: 100%;}
    .quotesWrapper .options .origin {width: 100%;margin-right: 0;}
  }
  @media (max-width: 425px) {
    .tab-panel .recent-search-item .details{
    display: flex;
  }
  }
`;

export const CustomDataGrid = styled(DataGrid)({
  border: 'none !important',
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: '400',
    fontSize: '14px',
    fontStyle: 'normal',
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#051551',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: '10px 15px !important',
    borderRight: '1px solid #CCC6C6',
  },
  '& .MuiDataGrid-columnHeaders button svg': {
    fill: '#FDAE15',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& .table_text': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell--withRenderer': {
    fontFamily: 'Roboto !important',
    borderRight: '1px solid #CCC6C6',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-cell--textLeft': {
    color: '#000000',
    fontWeight: '400',
    fontSize: '12px',
    borderBottom: '0 !important',
    overflow: 'scroll',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: '0 !important',
    '&:nth-child(1)': {
      cursor: 'pointer',
    },
    '&:nth-child(17)': {
      overflow: 'inherit !important',
      borderRight: 'none',
    },
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto !important',
  },
  '& .MuiDataGrid-cell:focus-within & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
});

export const AutoComplete = styled(Autocomplete)({
  '& .MuiSvgIcon-root ': {
    fill: '#051551',
  },
  '& .MuiFilledInput-root': {
    background: 'rgba(255,255,255,0.75) !important',
    border: '1px solid #BDBDBD !important',
    borderRadius: '3px !important',
    paddingRight: '24px !important',
    '&::before': {
      borderBottom: 'none',
    },
  },
});

export const CustomDatePicker = styled(DatePicker)({
  '& .MuiFilledInput-input': {
    backgroundColor: '#000',
  },
});
export const FlightDateTextField = styled(TextField)({
  '& .MuiSvgIcon-root ': {
    fill: '#051551 !important',
  },
  '& .MuiFilledInput-input': {
    paddingTop: '6px !important',
  },
  '& .MuiFilledInput-root': {
    background: 'rgba(255,255,255,0.75) !important',
    border: '1px solid #BDBDBD !important',
    borderRadius: '3px !important',
  },
});
