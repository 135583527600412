import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logout } from 'src/Helpers/auth';
import { createUploadLink } from 'apollo-upload-client';

export let httpUri: string | undefined = '';

export enum HostName {
  LOCALHOST = 'localhost',
  PROD_IP = '52.211.32.169',
  STAGING = 'staging.tricargo.aero',
  PROD_DNS = 'tricargo.aero',
}

switch (window.location.hostname) {
  case HostName.LOCALHOST:
    httpUri = process.env.REACT_APP_LOCAL_GRAPHQL_URL;
    break;
  case HostName.PROD_IP:
    httpUri = process.env.REACT_APP_HOST_GRAPHQL_URL;
    break;
  case HostName.STAGING:
    httpUri = process.env.REACT_APP_STAGING_HOST_GRAPHQL_URL;
    break;
  default:
    httpUri = process.env.REACT_APP_DNS_HOST_GRAPHQL_URL;
}

const httpLink = createHttpLink({
  uri: httpUri,
});

const uploadLink = createUploadLink({
  uri: httpUri,
});

const authLink = setContext((_, { headers }) => {
  const userConfigStr = localStorage.getItem('userConfig');
  const userConfig = userConfigStr ? JSON.parse(userConfigStr) : null;
  const token = userConfig?.token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.[0]?.message === 'User is not authenticated') {
    logout();
  }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, uploadLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
